<template>
	<div v-if="!inactive && !disabled && hasInterface('configfiles')" class="substantial-height">
		<table v-if="serverAttributes !== undefined" class="details-table mdc-typography--body1">
			<tr :class="{'disabled': disabled }">

				<!-- No Status Provided -->
				<td v-if="!hasValue(stageTimestamp) || (stageEnabled && stageTimestamp <= 0)"
					class="warnings"
				>
					<div class="actionsright" v-show="hasValue(stageActions)">
						<button class="mdc-button mdc-button--raised"
								v-for="action in stageActions"
								:key="action.label"
								:disabled="action.disabled"
								@click="action.callback"
						>
							<i aria-hidden="true" class="material-icons mdc-button__icon">{{ action.icon }}</i>
							<span class="mdc-button__label">{{ action.label }}</span>
						</button>
					</div>
					<p class="failed" v-show="connectedAgents === 0">
						Agent must be online (and authenticated) before it can be instructed to perform {{ stageName }}.
					</p>
					<p class="failed">
						API has not provided any {{ stageName }} status details. <span v-show="connectedAgents > 0">Please wait...</span>
					</p>
					<div class="spinner-container" v-show="connectedAgents > 0">
						<ProgressSpinner md-diameter="50"/>
					</div>
					<div>
						<pre>{{ stageData }}</pre>
					</div>
				</td>

				<!-- Disabled -->
				<td v-else-if="hasValue(stageData) && !stageEnabled">
					<div class="notice">
						<p class="mdc-typography--body1">The {{ stageName }} stage is not currently enabled on this
							server for this config mode.</p>
						<p class="mdc-typography--body1">You probably want to look at one of the other stages.</p>
					</div>
					<table class="embedded-table">
						<tbody>
						<tr>
							<th>Server</th>
							<td :title="selectedServer">{{ serverName }}.{{ serverPOP }}</td>
						</tr>
						<tr>
							<th>Mode</th>
							<td>{{ configMode }}</td>
						</tr>
						<tr>
							<th>Stage</th>
							<td>{{ stageName }}</td>
						</tr>
						<tr>
							<th class="">Enabled</th>
							<td class="">{{ stageEnabled }}</td>
						</tr>
						</tbody>
					</table>
				</td>

				<!-- Status Provided -->
				<td v-else-if="hasValue(stageData) && stageEnabled"
					:class="{
						'status-container': true,
						'failed': stageFailed || stageDoesNotApplyToLastConfig
					}"
				>
					<div class="actionsright" v-show="hasValue(stageActions)">
						<button class="mdc-button mdc-button--raised"
								v-for="action in stageActions"
								:key="action.label"
								:disabled="action.disabled"
								@click="action.callback"
						>
							<i aria-hidden="true" class="material-icons mdc-button__icon">{{ action.icon }}</i>
							<span class="mdc-button__label">{{ action.label }}</span>
						</button>
					</div>
					<div v-show="stageDoesNotApplyToLastConfig">
						<br/>
						<br/>
						<p class="failed">
							ERROR: The last known {{ stageName }} does not relate to the most recently applied
							configuration.
						</p>
						<br/>
						<hr/>
					</div>

					<table :class="{'embedded-table': true, 'old-data': stageDoesNotApplyToLastConfig}">
						<tbody>
						<tr>
							<th>Timestamp</th>
							<td>
								<Timestamp :unixtime="stageTimestamp" :wide="true"/>
							</td>
						</tr>
						<tr>
							<th>Version</th>
							<td>{{ stageVersion }}</td>
						</tr>
						<tr>
							<th>Status</th>
							<td>
								<span v-show="!stageFailed" class="ok">SUCCESS</span>
								<span v-show="stageFailed" class="failed">
									FAILED
									<span v-show="stageRetries > 0"> &nbsp; ({{ stageRetries }} attempts)</span>
								</span>
								<span v-show="stageTextWarnings > 0 || stageTextErrors > 0"> &nbsp; - &nbsp;</span>
								<span v-show="stageTextErrors > 0" class="failed">
									ERRORS: <span>{{ stageTextErrors }}</span> &nbsp;
								</span>
								<span v-show="stageTextWarnings > 0" class="warning">
									WARNINGS: <span>{{ stageTextWarnings }}</span> &nbsp;
								</span>
							</td>
						</tr>
						<tr>
							<th>Duration</th>
							<td v-if="stageDuration > 0">
								<div>
									<span v-if="stageDurationHuman !== undefined"
										  :title="stageDuration + ' ms'"
									>
										{{ stageDurationHuman }}
									</span>
									<span v-else>{{ stageDuration }} ms</span>
								</div>
								<div>
									<p v-if="stageDuration > 25000" class="failed">
										<!-- 25 seconds -->
										CRITICAL NOTICE: {{ stageName }} was very slow.
									</p>
									<p v-else-if="stageDuration > 15000" class="warning">
										<!-- 15 seconds -->
										NOTICE: {{ stageName }} was slow.
									</p>
								</div>
							</td>
							<td v-else>No performance data available</td>
						</tr>
						</tbody>
					</table>

					<br/>

					<Tabs
						:navigate="false"
						:nested="true"
						:narrow="true"
						:tabItems="outputTabsItems"
					>
						<div v-if="currentOutputTab==='errors' && hasValue(stageTextErrorsLines)"
							 class="substantial-height">
							<div :class="{'output-box': true, 'old-data': stageDoesNotApplyToLastConfig}">
								<p class="mdc-typography--body1">Errors (Detected in the output):</p>
								<div v-for="(line, index) in stageTextErrorsLines" :key="index">
								<pre v-highlightjs="reformatErrorString(line)"><code
									class="{ 'language-nginxlog': hasNginxLines }"
								>{{ reformatErrorString(line) }}</code></pre>
								</div>
							</div>
						</div>
						<div v-else-if="currentOutputTab==='warnings' && hasValue(stageTextWarningsLines)"
							 class="substantial-height">
							<div :class="{'output-box': true, 'old-data': stageDoesNotApplyToLastConfig}">
								<p class="mdc-typography--body1">Warnings (Detected in the output):</p>
								<div v-for="(line, index) in stageTextWarningsLines" :key="index">
								<pre v-highlightjs="reformatErrorString(line)"><code
									class="{ 'language-nginxlog': hasNginxLines }"
								>{{ reformatErrorString(line) }}</code></pre>
								</div>
							</div>
						</div>
						<div v-else-if="currentOutputTab==='stdout'" class="substantial-height">
							<div :class="{'output-box': true, 'old-data': stageDoesNotApplyToLastConfig}">
								<p class="mdc-typography--body1">Standard Output:</p>
								<pre v-if="!hasValue(stageResult)">No Result</pre>
								<pre v-else v-highlightjs="stageResult"><code
									class="{ 'language-nginxlog': hasNginxLines }"
								>{{ stageResult }}</code></pre>
							</div>
						</div>
						<div v-else-if="currentOutputTab==='stderr'" class="substantial-height">
							<div :class="{'output-box': true, 'old-data': stageDoesNotApplyToLastConfig}">
								<p class="mdc-typography--body1">Standard Error:</p>
								<pre v-if="!hasValue(stageError)">No Result</pre>
								<pre v-else v-highlightjs="stageError"><code
									class="{ 'language-nginxlog': hasNginxLines }"
								>{{ stageResult }}</code></pre>
							</div>
						</div>
						<div v-else class="substantial-height">
							<p>&nbsp;</p>
						</div>
					</Tabs>

				</td>
			</tr>
		</table>
	</div>
</template>

<script>

import {mapGetters} from 'vuex'
import * as moment from 'moment/moment'
import * as hmd from 'humanize-duration'

import CachedProvAirportsMixin from '../../../common/CachedProvAirportsMixin'
import ProgressSpinner from '../../../material/ProgressSpinner'
import ProvisioningAPI from '../../../common/ProvisioningAPI'
import Timestamp from '../../../material/Timestamp'

import hljs from 'highlight.js'
import nginxlog from './nginxlog.js'
import Tabs from '../../../material/Tabs.vue'

hljs.registerLanguage('nginxlog', nginxlog)

export default {
	name: 'ServerDetailsScriptStage',
	mixins: [
		CachedProvAirportsMixin,
		ProvisioningAPI,
	],
	components: {
		Tabs,
		ProgressSpinner,
		Timestamp,
	},
	props: [
		'selectedServer',
		'configMode',
		'serverName',
		'serverPOP',
		'serverAttributes',
		'activeInterfaces',
		'stageData',
		'stageName', // i.e. reload
		'stageActions',
		'hasNginxLines'
	],
	data: function () {
		return {
			selectedOutputTab: undefined,
		}
	},
	created () {
		// console.debug('ServerDetailsScriptStage: created()')
	},
	mounted () {
		// console.debug('ServerDetailsScriptStage: mounted()')
		this.styleNginxLines()
	},
	updated () {
		// console.debug('ServerDetailsScriptStage: updated()')
		this.styleNginxLines()
	},
	watch: {},
	computed: {
		...mapGetters([
			'appConfig',
			'websocketConnected',
		]),

		// ------------------------------------------------------
		// Status / Health Monitoring

		inactive () {
			return !this.loading && this.serverAttributes === undefined
		},
		disabled () {
			if (this.serverAttributes === undefined) {
				return false
			}
			return this.serverAttributes.disabled === true
		},
		connectedAgents () {
			if (this.serverAttributes === undefined) {
				return 0
			}
			return this.serverAttributes.connected
		},
		online () {
			return this.connectedAgents > 0
		},

		// ------------------------------------------------------
		// Configuration / Versioning

		serverConfiguration () {
			if (!this.hasValue(this.serverAttributes)) {
				return undefined
			}
			return this.serverAttributes.configuration
		},
		serverConfigurationLast () {
			if (!this.hasValue(this.serverConfiguration) || !this.hasValue(this.serverConfiguration.last)) {
				return undefined
			}
			let summary = this.serverConfiguration.last // Version Summary (can be null)
			if (!this.hasValue(summary.version) || !this.hasValue(summary.timestamp)) {
				return undefined
			}
			return this.matchVersionSummaryToHistory(summary)
		},

		serverConfigurationLastVersion () {
			if (this.serverConfigurationLast === undefined) {
				return undefined
			}
			return this.serverConfigurationLast.version
		},
		serverConfigurationLastTimestamp () {
			if (this.serverConfigurationLast === undefined) {
				return undefined
			}
			return this.nanoToUnix(this.serverConfigurationLast.timestamp)
		},

		// ------------------------------------------------------
		// Stage

		stageEnabled () {
			if (this.stageData === undefined) {
				return false
			}
			return this.stageData.enabled === true
		},
		stageTimestamp () {
			if (this.stageData === undefined) {
				return undefined
			}
			if (this.stageData.last >= 9999999999) {
				return this.nanoToUnix(this.stageData.last)
			}
			return this.stageData.last
		},
		stageFailed () {
			if (this.stageData === undefined) {
				return false
			}
			return this.stageData.failed === true || this.stageData.textErrors > 0
		},
		stageDuration () {
			if (this.stageData === undefined) {
				return 0
			}
			return this.stageData.duration
		},
		stageDurationSeconds () {
			if (this.stageData === undefined) {
				return 0
			}
			return Math.round(this.stageData.duration / 1000)
		},
		stageDurationHuman() {
			if (this.stageDuration === undefined) {
				return undefined
			}
			return hmd.default(this.stageDuration)
			// return moment.duration(this.stageDuration, 'milliseconds').humanize()
		},
		stageRetries () {
			if (this.stageData === undefined) {
				return 0
			}
			return this.stageData.retries
		},
		stageVersion () {
			if (this.stageData === undefined) {
				return undefined
			}
			return this.stageData.version
		},

		stageResult () {
			if (this.stageData === undefined) {
				return 0
			}
			return this.stageData.result
		},
		stageError () {
			if (this.stageData === undefined) {
				return 0
			}
			return this.stageData.error
		},
		stageText () {
			if (this.stageData === undefined) {
				return 0
			}
			return this.stageData.result + '\n' + this.stageData.error
		},
		stageTextWarnings () {
			if (this.stageData === undefined) {
				return 0
			}
			return this.stageData.textWarnings
		},
		stageTextErrors () {
			if (this.stageData === undefined) {
				return 0
			}
			if (this.stageData.textErrors !== undefined) {
				return this.stageData.textErrors
			}
			return 0
		},
		stageTextWarningsLines () {
			if (this.hasValue(this.stageText)) {
				const keywords = ['[WARNING]', 'WARNING', '[notice]', '[warning]', '[warn]']
				let lines = this.stageText.split('\n')
				lines = lines.filter((line) => {
					return keywords.some((keyword) => line.includes(keyword))
				})
				return lines
			}
			return undefined
		},
		stageTextErrorsLines () {
			if (this.hasValue(this.stageText)) {
				const keywords = ['[ERROR]', 'ERROR', '[error]', '[crit]', '[alert]', '[emerg]']
				let lines = this.stageText.split('\n')
				lines = lines.filter((line) => {
					return keywords.some((keyword) => line.includes(keyword))
				})
				return lines
			}
			return undefined
		},

		stageDoesNotApplyToLastConfig () {
			let stageTimestamp = this.stageTimestamp
			let configTimestamp = this.serverConfigurationLastTimestamp
			if (stageTimestamp === undefined || configTimestamp === undefined) {
				return undefined
			}
			if (stageTimestamp < configTimestamp) {
				return true // true == does not relate (failed)
			}

			let stageVersion = this.stageVersion
			let configVersion = this.serverConfigurationLastVersion
			if (stageVersion !== undefined && configVersion !== undefined) {
				if (stageVersion !== configVersion) {
					return true // true == does not relate (failed)
				}
			}

			return false
		},

		// ------------------------------------------------------
		// Stage

		hasErrorsTab () {
			return this.hasValue(this.stageTextErrorsLines) && this.stageTextErrorsLines.length > 0
		},
		hasWarningsTab () {
			return this.hasValue(this.stageTextWarningsLines) && this.stageTextWarningsLines.length > 0
		},
		hasStdErrTab () {
			return this.hasValue(this.stageError)
		},

		outputTabsItems () {
			let result = []
			let errorsLabel = 'Errors'
			if (this.hasErrorsTab) {
				errorsLabel += ' (' + this.stageTextErrorsLines.length + ')'
			}
			result.push({
				'id': 'errors',
				'label': errorsLabel,
				'icon': undefined,
				// 'postIcon': 'warning',
				// 'color': 'red',
				'narrow': true,
				'callback': args => this.showOutputTab(args[1].id),
				'hidden': !this.hasErrorsTab,
				'active': this.currentOutputTab === 'errors',
			})
			let warningsLabel = 'Warnings'
			if (this.hasWarningsTab) {
				warningsLabel += ' (' + this.stageTextWarningsLines.length + ')'
			}
			result.push({
				'id': 'warnings',
				'label': warningsLabel,
				'icon': undefined,
				// 'postIcon': 'warning',
				// 'color': 'darkorange',
				// 'color': 'red',
				'narrow': true,
				'callback': args => this.showOutputTab(args[1].id),
				'hidden': !this.hasWarningsTab,
				'active': this.currentOutputTab === 'warnings',
			})
			result.push({
				'id': 'stdout',
				'label': 'STDOUT',
				'icon': undefined,
				// 'postIcon': false ? 'warning' : undefined,
				// 'color': false ? 'red' : undefined,
				// 'color': this.stageFailed ? 'red' : undefined,
				'narrow': true,
				'callback': args => this.showOutputTab(args[1].id),
				'active': this.currentOutputTab === 'stdout',
			})
			result.push({
				'id': 'stderr',
				'label': 'STDERR',
				'icon': undefined,
				// 'postIcon': this.stageFailed ? 'warning' : undefined,
				// 'color': this.stageFailed ? 'red' : undefined,
				// 'color': this.stageFailed ? 'red' : undefined,
				'narrow': true,
				'callback': args => this.showOutputTab(args[1].id),
				'hidden': !this.hasStdErrTab,
				'active': this.currentOutputTab === 'stderr',
			})
			return result
		},
		currentOutputTab () {
			let result = 'stdout'
			if (this.hasValue(this.selectedOutputTab)) {
				result = this.selectedOutputTab
			} else if (this.hasErrorsTab) {
				result = 'errors'
			} else if (this.hasWarningsTab) {
				result = 'warnings'
			}

			// noinspection FallThroughInSwitchStatementJS
			switch (result) {
			case 'errors':
				if (this.hasErrorsTab) {
					return 'errors'
				}
				// eslint-disable-next-line no-fallthrough
			case 'warnings':
				if (this.hasWarningsTab) {
					return 'warnings'
				}
				// eslint-disable-next-line no-fallthrough
			case 'stdout':
				return 'stdout'
			case 'stderr':
				return 'stderr'

			default:
				return 'stdout'
			}
		},

	},
	directives: {
		// https://www.metachris.com/2017/02/vuejs-syntax-highlighting-with-highlightjs/
		'highlightjs': {
			deep: true,
			bind: (el, binding) => {
				// on first bind, highlight all targets
				let targets = el.querySelectorAll('code')
				targets.forEach((target) => {
					// if a value is directly assigned to the directive, use this
					// instead of the element content.
					if (binding.value) {
						target.textContent = binding.value
					}
					hljs.highlightElement(target)
				})
			},
			componentUpdated: (el, binding) => {
				// after an update, re-fill the content and then highlight
				let targets = el.querySelectorAll('code')
				targets.forEach((target) => {
					if (binding.value) {
						target.textContent = binding.value
						hljs.highlightElement(target)
					}
				})
			}
		},
	},
	methods: {
		hasInterface (name) {
			return this.activeInterfaces.includes(name)
		},

		showOutputTab (id) {
			this.selectedOutputTab = id
		},

		// ------------------------------------------------------------

		styleNginxLines () {
			if (this.hasNginxLines !== true) {
				return
			}
			// console.debug('el', this.$el)
			if (this.$el === undefined || this.$el.querySelectorAll === undefined) {
				return
			}
			this.$el.querySelectorAll('.hljs-addition').forEach((el) => {
				el.classList.add('nginx-warning')
			})
			this.$el.querySelectorAll('.hljs-deletion').forEach((el) => {
				el.classList.add('nginx-emergency')
			})
		},

		reformatErrorString (str) {
			return str.split(' | ').join('\n')
		},

		// ------------------------------------------------------------

		nanoToUnix (timestamp) {
			const nanoSecondsToSeconds = ts => ts / 1000000000
			return Math.round(nanoSecondsToSeconds(timestamp))
		},
		matchVersionSummaryToHistory (summary) {
			let result = summary
			if (!this.hasValue(this.serverAttributes) || !this.hasValue(this.serverAttributes.config_history)) {
				return result
			}
			if (!this.hasValue(summary.version) || !this.hasValue(summary.timestamp)) {
				return result
			}
			let summaryVersion = summary.version
			let summaryTimestamp = summary.timestamp
			let found = false
			this.serverAttributes.config_history.forEach(entry => {
				if (found) {
					return
				}
				if (!this.hasValue(entry)) {
					return
				}
				if (entry.version === summaryVersion && entry.timestamp === summaryTimestamp) {
					result = {...entry}
					result.summary = summary
					found = true
				}
			})
			return result
		},

		// ------------------------------------------------------------

		requestReconfigure () {
			this.$emit('request-action', {action: 'reconfigure'})
		},
		requestReload () {
			this.$emit('request-action', {action: 'reload'})
		},
		requestPing () {
			this.$emit('request-action', {action: 'ping'})
		},

	}
}

</script>

<style>
/* These styles have to be global :( */
.nginx-warning {
	background: darkorange;
	font-weight: 700;
}

.nginx-emergency {
	background: lightcoral;
	/*border: 1px solid red;*/
	font-weight: 700;
}
</style>

<style lang="scss" scoped>

.substantial-height {
	min-height: 60vh;
}

.group-pill {
	pointer-events: none;
	border: 1px solid lightgrey;
	padding: 10px 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	margin: 5px 13px 5px -1px;
	border-radius: 100px;
	/*background-color: #f3e2fc;*/
	/*color: #a311db;*/
	/*background-color: #448aff;*/
	color: black;
	font-weight: bold;
	text-transform: uppercase;
}

.details-table {
	margin: 8px 0 32px;
	border: 1px solid darkgrey;
	border-collapse: collapse;
	border-spacing: 0;
	table-layout: fixed;
	width: 100%;
	max-width: 100%;
}

.details-table td, .details-table th {
	border: 1px solid darkgrey;
	padding: .5em;
	text-align: left;
	//vertical-align: top;
	vertical-align: middle;
	line-height: 2em;
}

.details-table th {
	background: #6200ee;
	font-weight: normal;
	color: white;
	text-align: left;
	vertical-align: top;
}

/*
.details-table td[data-v-e1d37a], .details-table th[data-v-e1d37a] {
    border: 1px solid darkgrey;
    padding: 0.5em;
    text-align: left;
    vertical-align: top;
    overflow: hidden;
    word-break: break-all;
    max-width: 50%;
 */

.details-table th {
	min-width: 15%;
	max-width: 25%;
	width: 20%;
}

.details-table td {
	min-width: 25%;
	max-width: 80%;
	width: 25%;
}

//th.tall-row, td.tall-row {
//	vertical-align: middle;
//	line-height: 2em;
//}

.details-table tr {
	background-color: white;
}

.details-table tr:hover {
	background-color: lightyellow;
}

.embedded-table {
	margin: 0;
	padding: 0;
	width: 100%;
	max-width: 100%;
	table-layout: fixed;
	border: 0;
	border-collapse: collapse;
	border-spacing: 0;
}

.embedded-table tr {
	background-color: transparent;
}

.embedded-table th, .embedded-table td {
	border: 0;
	width: 49%;
	background-color: transparent;
	color: black;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.embedded-table th.wide, .embedded-table td.wide {
	width: 69%;
}

.embedded-table th.narrow, .embedded-table td.narrow {
	width: 20%;
}

.embedded-table th {
	border-right: 1px solid darkgrey;
	border-bottom: 1px solid darkgrey;
}

.embedded-table th.hlast {
	border-right: 0;
}

.embedded-table td {
	border-left: 1px solid darkgrey;
	border-bottom: 1px solid darkgrey;
}

.embedded-table .last {
	border-bottom: 0;
}

.embedded-table tr {
	opacity: 0.9;
}

.embedded-table tr:hover {
	background-color: lightgoldenrodyellow;
	opacity: 1;
}

.embedded-table tr:last-of-type {
	border-bottom: 0;
}

.embedded-table tr:last-of-type th {
	border-bottom: 0;
}

.embedded-table tr:last-of-type td {
	border-bottom: 0;
}

.material-icons {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
}

.timestamp-message {
	clear: both;
	margin: 0.25em;
	padding: 0.25em;
}

ul {
	margin: 0;
}

p {
	margin: 0 0 1em;
}

.spinner-container {
	text-align: center;
	vertical-align: middle;
	padding: 1em;
}

.notice {
	clear: both;
	border: 1px dashed lightblue;
	border-left: 0;
	border-right: 0;
	padding: 0.5em;
	margin: 0.5em;
	//background-color: rgba(255, 179, 57, 0.69);
	//color: darkred;
}

.warnings, .warning {
	clear: both;
	border: 1px dashed darkorange;
	border-left: 0;
	border-right: 0;
	padding: 0.5em;
	margin: 0.5em;
	background-color: rgba(255, 179, 57, 0.69);
	color: darkred;
}

.failed {
	clear: both;
	border: 1px dashed red;
	border-left: 0;
	border-right: 0;
	padding: 0.5em;
	margin: 0.5em;
	background-color: rgba(245, 230, 230, 0.5);
	color: darkred;
}

pre {
	max-width: 100%;
	overflow: hidden;
	white-space: pre-wrap;
	border: 1px solid lightgray;
	padding: 0.5em;
	margin: 0.5em;
	background-color: whitesmoke;
}

.failed pre {
	font-weight: normal;
	color: black;
	border: 1px solid lightcoral;
}

.ok {
	color: darkgreen;
}

.good {
	background-color: lightgreen;
}

.embedded-table td.good {
	color: darkgreen !important;
}

.unhappy {
	background-color: #ffbf00;
}

.embedded-table td.unhappy {
	color: darkorange !important;
}

.bad {
	background-color: #f18787;
}

.embedded-table th.bad, .embedded-table td.bad {
	color: darkred !important;
}

.disabled {
	opacity: 0.5;
}

tr.disabled th {
	background-color: darkgray;
}

.actionsright {
	float: right;
	margin-left: 1em;
	margin-bottom: 1em;
}

.actionsright .mdc-button {
	margin-left: 0.5em;
}

.actionsvspace {
	margin-bottom: 0.5em;
}

.floating-indicator {
	float: right;
	margin-right: 0.5em;
}

.yellow-star {
	color: #ffbf00;
}

.red-engineer {
	color: orangered;
}

.output-box {
	min-height: 20vh;
	padding-top: 1em;
}

.output-box p {
	margin-left: 0.5em;
	margin-bottom: 0;
}

.old-data {
	opacity: 0.5;
}

</style>
