<template>
	<div aria-describedby="my-dialog-content"
		 aria-labelledby="my-dialog-title"
		 aria-modal="true"
		 class="mdc-dialog page-dialog"
		 data-mdc-auto-init="MDCDialog"
		 role="alertdialog">
		<div class="mdc-dialog__container page-dialog-container">
			<div :style="sizeStyle"
				 class="mdc-dialog__surface page-dialog-surface"
			><!--
            Title cannot contain leading whitespace due to mdc-typography-baseline-top()
                --><h2 v-if="hasTitle" class="mdc-dialog__title page-dialog-title"><!--
     -->{{ title }}<!--
   --></h2>
				<div class="mdc-dialog__content mdc-typography page-dialog-content"
					 :class="{ 'nopadding': withoutPadding === true }"
				>
					<slot>
						<!-- Dialog contents will be placed here -->
					</slot>
				</div>
				<footer v-if="actualActions !== undefined" class="mdc-dialog__actions">
					<button v-for="action in actualActions"
							v-bind:key="action.id"
							v-bind:disabled="action.disabled"
							class="mdc-button mdc-dialog__button"
							type="button"
							v-on:click="clickHandler(action)"
					>
						<span class="mdc-button__label">{{ action.label }}</span>
					</button>
				</footer>
			</div>
		</div>
		<div class="mdc-dialog__scrim"></div>
	</div>
</template>

<script>

// Material Design Components
// noinspection NpmUsedModulesInstalled
import autoInit from '@material/auto-init'

export default {
	props: [
		'open',
		'title',
		'actions',
		'addClose',
		'withoutPadding',
		'minWidth', // specified in vw
		'minHeight', // specified in vh
		'width', // specified in vw
		'height', // specified in vh
		'maxWidth', // specified in vw
		'maxHeight', // specified in vh
	],
	data: function () {
		return {
			'dialog': undefined,
			'payingAttention': false,
		}
	},
	computed: {
		hasTitle () {
			return this.title !== undefined && this.title.length > 0
		},
		isOpen () {
			if (this.dialog === undefined) {
				return false
			}
			return this.dialog.isOpen
		},
		actualActions () {
			let result = this.actions
			if (this.addClose === true) {
				if (result === undefined) {
					result = []
				}
				result.push({
					'id': 'close',
					'label': 'Close',
					'callback': this.closeDialog
				})
			}
			return result
		},
		sizeStyle () {
			let result = ''
			if (this.minWidth !== undefined) {
				result += 'min-width: ' + this.minWidth + 'vw;'
			}
			if (this.minHeight !== undefined) {
				result += 'min-height: ' + this.minHeight + 'vh;'
			}
			if (this.width !== undefined) {
				result += 'width: ' + this.width + 'vw;'
			}
			if (this.height !== undefined) {
				result += 'height: ' + this.height + 'vh;'
			}
			if (this.maxWidth !== undefined) {
				result += 'max-width: ' + this.maxWidth + 'vw;'
			}
			if (this.maxHeight !== undefined) {
				result += 'max-height: ' + this.maxHeight + 'vh;'
			}
			return result
		}
	},
	watch: {
		dialog () {
			this.setupListeners()
		},
		open (value) {
			if (this.isOpen !== value) {
				if (value) {
					this.openDialog()
				} else {
					this.closeDialog()
				}
			}
			this.$emit('update:open', value)
			if (!value) {
				this.$emit('closed', '')
			}
		},
	},
	mounted () {
		this.initialiseMaterialDesignComponents()
		this.dialog = this.$el.MDCDialog
		this.setupListeners()
		if (this.open) {
			this.openDialog()
		}
	},
	updated () {
		this.initialiseMaterialDesignComponents()
		this.layoutDialog()
	},
	methods: {
		initialiseMaterialDesignComponents () {
			try {
				autoInit()  // Material Design Components
			} catch (error) {
				console.error(error)
			}
		},
		layoutDialog () {
			try {
				this.dialog.layout()
			} catch (error) {
				console.error(error)
			}
		},
		setupListeners () {
			this.dialog.listen('MDCDialog:opened', () => {
				this.$el.setAttribute('aria-hidden', 'true')
				this.$emit('update:open', true)
			})
			this.dialog.listen('MDCDialog:closed', () => {
				this.$el.removeAttribute('aria-hidden')
				this.$emit('update:open', false)
			})
		},
		openDialog () {
			if (!this.isOpen) {
				this.dialog.open()
			}
		},
		closeDialog () {
			if (this.isOpen) {
				this.dialog.close()
			}
		},
		clickHandler (action) {
			if (action.callback !== undefined) {
				action.callback(action, this)
			} else {
				this.$emit('button-clicked', action, this)
				this.dialog.close()
			}
		},
	},
}
</script>

<style lang="scss" scoped>

.page-dialog-surface {
	min-width: 30vw;
	max-width: 100vw;
	min-height: 30vh;
	max-height: 100vh;
}

.page-dialog-content {
	display: inline;
	position: relative;
}

.nopadding {
	padding: 0;
}

</style>
