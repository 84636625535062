<template>
	<div :class="{ 'tab-container': true, 'nested-tab-container': nested === true && narrow !== true }">
		<GenericContainer>
			<div :class="{ 'mdc-tab-bar': true, 'nested-tab-bar': nested === true }" role="tablist">
				<div class="mdc-tab-scroller">
					<div class="mdc-tab-scroller__scroll-area">
						<div class="mdc-tab-scroller__scroll-content">
							<Tab
								v-for="item in tabItems"
								v-show="item.hidden !== true"
								v-bind:id="item.id"
								:key="item.id"
								v-bind:active="isActive(item)"
								v-bind:callback="item.callback"
								v-bind:hidden="item.hidden"
								v-bind:icon="item.icon"
								v-bind:postIcon="item.postIcon"
								v-bind:label="item.label"
								v-bind:color="item.color"
								v-bind:narrow="item.narrow"
								v-bind:glowing="item.glowing"
								v-bind:nested="nested"
								v-bind:routeParams="item.routeParams"
								v-bind:selectedTabId="actualSelectedTabId"
								v-on:tab-click="clickHandler"
							/>
						</div>
					</div>
				</div>
			</div>
		</GenericContainer>
		<div :class="{ 'tabs-content': true, 'nested-tabs-content': nested === true }">
			<slot>
				<!-- TAB CONTENT -->
			</slot>
		</div>
	</div>
</template>

<script>
import autoInit from '@material/auto-init'

import Tab from './TabsTab.vue'
import GenericContainer from './GenericContainer'

export default {
	components: {
		GenericContainer,
		Tab
	},
	props: [
		'tabItems',
		'selectedTabId',
		'navigate',
		'nested',
		'narrow',
		'useStartsWith',
	],
	computed: {
		actualSelectedTabId () {
			if (this.selectedTabId !== undefined) {
				return this.selectedTabId
			}
			if (this.$route.meta.selectedTabId !== undefined) {
				return this.$route.meta.selectedTabId
			}
			return this.$route.name
		}
	},
	mounted () {
		autoInit()
	},
	updated () {
		autoInit()
	},
	methods: {
		isActive (item) {
			if (item.hidden === true) {
				return false
			}
			if (item.active === true) {
				return true
			}
			if (item.id === this.actualSelectedTabId) {
				return true
			}
			if (this.useStartsWith === true && this.actualSelectedTabId.startsWith(item.id)) {
				return true
			}
			return false
		},
		clickHandler (event) {
			if (this.navigate !== true) {
				this.$emit('tab-click', [event[0], event[1], this])
			} else {
				let params = event[1].routeParams
				if (typeof params === 'object' && !Array.isArray(params) && params !== null ) {
					// prevent snapping page back to top on tab change
					params.savePosition = true
				}
				this.$router.push({
					'name': event[1].id,
					'params': params,
				})
			}
		},
	}
}

</script>

<style lang="scss" scoped>

.tabs-content {
	padding-top: 1em;
}

.nested-tab-container {
	margin: 0 2em;
}

.nested-tab-bar {
	//background: whitesmoke;
	//background: linear-gradient(90deg, rgba(245, 245, 245, 0.5) 0%, rgba(245, 245, 245, 1) 50%, rgba(245, 245, 245, 0.5) 100%);
	padding: 0;
}

</style>
