<template>
	<div>
		<div class="action-menu-container mdc-menu-surface--anchor">
			<button class="mdc-button mdc-button--outlined"
					v-bind:disabled="actions === undefined || actions.length === 0 || pending === true"
					v-on:click="openActionMenu"
			>
				<i aria-hidden="true" class="material-icons mdc-button__icon">menu_open</i>
				<span class="mdc-button__label">{{ title }}</span>
			</button>
			<div v-if="actions !== undefined && actions.length > 0"
				 class="action-menu mdc-menu-surface mdc-typography"
				 data-mdc-auto-init="MDCMenuSurface"
			>
				<h3 class="mdc-typography--headline6">{{ title }}</h3>
				<div v-if="message !== undefined" class="max-width">
					<p class="mdc-typography--body2">{{ message }}</p>
				</div>
				<div v-show="pending" class="max-width centered">
					<ProgressSpinner v-bind:mdDiameter="50"/>
				</div>
				<ActionsButtons
					:actions="actions"
					:wide="true"
					:singleWordLabels="true"
				/>
			</div>
		</div>
	</div>
</template>

<script>

// Material Design Components
// noinspection NpmUsedModulesInstalled
import autoInit from '@material/auto-init'

import ActionsButtons from './ActionsButtons'
import ProgressSpinner from '../material/ProgressSpinner'

export default {
	name: 'ActionsMenu',
	components: {
		ActionsButtons,
		ProgressSpinner
	},
	props: [
		'title',
		'open',
		'message',
		'actions',
		'pending'
	],
	data () {
		return {
			'menuSurfaceElement': undefined,
			'menuSurface': undefined
		}
	},
	computed: {
		isOpen () {
			if (this.menuSurface === undefined) {
				return false
			}
			return this.menuSurface.isOpen
		},
	},
	watch: {
		open (value) {
			if (value) {
				this.openActionMenu()
			} else {
				this.closeActionMenu()
			}
			this.$emit('update:open', value)
		},
		menuSurface () {
			this.listenForEvents()
		}
	},
	mounted () {
		autoInit()  // Material Design Components
		this.$nextTick(() => {
			this.menuSurfaceElement = this.$el.querySelector('.mdc-menu-surface')
			if (this.menuSurfaceElement !== undefined && this.menuSurfaceElement !== null) {
				this.menuSurface = this.menuSurfaceElement.MDCMenuSurface
				this.listenForEvents()
			}
		})
	},
	updated () {
		autoInit()  // Material Design Components
		this.menuSurfaceElement = this.$el.querySelector('.mdc-menu-surface')
		if (this.menuSurfaceElement !== undefined && this.menuSurfaceElement !== null) {
			this.menuSurface = this.menuSurfaceElement.MDCMenuSurface
			this.listenForEvents()
		}
	},
	methods: {
		listenForEvents () {
			this.menuSurface.listen('MDCMenuSurface:opened', () => {
				this.$emit('update:open', true)
			})
			this.menuSurface.listen('MDCMenuSurface:closed', () => {
				this.$emit('update:open', false)
			})
		},
		openActionMenu () {
			this.menuSurface.open()
			this.$emit('update:open', true)
		},
		closeActionMenu () {
			this.menuSurface.close()
			this.$emit('update:open', false)
		},
		singleWord (label) {
			if (label.includes(' ')) {
				return label.split(' ')[0]
			}
			return label
		},
	}
}
</script>

<style lang="scss" scoped>

.action-menu-container {
	min-width: 200px;
	text-align: right;
	margin-right: 40px;
}

.action-menu {
	width: 260px;
	text-align: left;
	padding: 1em;
}

.action-menu h3 {
	margin: 0;
	padding-bottom: .5em;
}

//.action-menu li {
//	padding: 0.25em 1em;
//}

//.action-menu .mdc-button {
//	width: 100%;
//}


</style>
