//
// Application Configuration plugin for Vuex.
//
// @see https://vuex.vuejs.org/guide/plugins.html
//
// @author: Brian Wojtczak, brian.wojtczak@cachefly.com
// @copyright: Copyright 2020 by CacheNetworks, LLC. All Rights Reserved.

import axios from 'axios'

export const APP_CONFIG_ENVIRONMENT = 'APP_CONFIG_ENVIRONMENT'
export const APP_CONFIG_LOAD = 'APP_CONFIG_LOAD'
export const APP_CONFIG_ERROR = 'APP_CONFIG_ERROR'

export default function createAppConfigPlugin (options) {
	return function appConfigPlugin (store) {

		if (!('environment' in options)) {
			options.environment = process.env.NODE_ENV
		}

		// This module only handles state about the app configuration instance
		store.registerModule('appconfig', {
			strict: process.env.NODE_ENV !== 'production',
			state: {
				environment: undefined,
				configuration: undefined,
				configLoaded: false,
				configError: undefined
			},
			getters: {
				appConfigEnvironment: state => state.environment,
				appConfigError: state => state.configError,
				appConfigLoaded: state => state.configLoaded,
				appConfig: state => name => {
					if (state.configLoaded) {
						if (name === undefined) {
							return state.configuration
						}
						return state.configuration[name]
					}
					return undefined
				},
			},
			mutations: {
				[APP_CONFIG_ENVIRONMENT] (state, payload) {
					state.environment = payload
					state.configLoaded = false
					state.configuration = undefined
				},
				[APP_CONFIG_LOAD] (state, payload) {
					state.configuration = payload
					state.configLoaded = true
				},
				[APP_CONFIG_ERROR] (state, payload) {
					console.error('vuex config: error:', payload)
					state.configError = payload
					state.configLoaded = false
				},
			},
			actions: {
				appConfigSwitchEnvironment: (context, payload) => {
					// console.debug('vuex config: setting environment to', payload.environment)
					context.commit(APP_CONFIG_ENVIRONMENT, payload.environment)
					context.dispatch('appConfigReload')
				},
				appConfigReload: (context) => {
					let environment = context.getters.appConfigEnvironment
					console.debug('vuex config: requesting config for', environment, 'environment')
					setTimeout(() => {
						axios.get(
							'./_/config/?env=' + environment,
							{
								withCredentials: true,
							}
						).then(function (response) {
							if (response.data !== undefined && typeof (response.data) === 'object') {
								context.commit(APP_CONFIG_LOAD, response.data)
							} else {
								context.commit(APP_CONFIG_ERROR, 'Invalid response from server')
							}
						}).catch(function (error) {
							context.commit(APP_CONFIG_ERROR, error)
						})
					}, 10)
				},
			},
		})

		store.dispatch({
			type: 'appConfigSwitchEnvironment',
			environment: options.environment,
		})

	}
}
