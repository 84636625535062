<template>
	<div class="server-real-time-data">
		<p v-show="!hasprogressItems" class="mdc-typography--body1 none">
			No activity data available.
		</p>
		<table class="embedded-table mdc-typography--body1">
			<tbody v-show="hasprogressItems">
			<tr v-for="item in progressItems"
				:key="item.server+item.id"
				:class="{
					'good': item.status === 'good',
					'unhappy': item.status === 'unhappy',
					'bad': item.status === 'bad',
				}"
				@click="openDetailsDialog(item)"
			>

				<td class="hfirst narrow"
					:class="{
						'good': item.status === 'good',
						'unhappy': item.status === 'unhappy',
						'bad': item.status === 'bad',
						'subtle-good': item.status === 'pending' && item.serverStatus === 'good',
						'subtle-unhappy': item.status === 'pending' && item.serverStatus === 'unhappy',
						'subtle-bad': item.status === 'pending' && item.serverStatus === 'bad',
					}"
					:title="item.server"
				>
					<span>{{ item.server }}</span>
				</td>
				<td class="wide"
					:class="{
						'good': item.status === 'good',
						'unhappy': item.status === 'unhappy',
						'bad': item.status === 'bad',
					}"
					:title="item.activity +' - '+item.message"
				>
					<span v-show="item.forced" class="float-right red-engineer">
						<i class="inline-icon material-icons red-engineer"
						   :title="'Forced by ' + item.username"
						>engineering</i> &nbsp;
					</span>

					<span><strong>{{ item.activity }}</strong></span>
					-
					<span>{{ item.message }}</span>
				</td>
				<td class="hlast narrow"
					:class="{
						'good': item.status === 'good',
						'unhappy': item.status === 'unhappy',
						'bad': item.status === 'bad',
					}"
					v-if="item.completed !== true"
					title="Pending. Please wait..."
				>
					<span>
						<ProgressSpinner md-diameter="15"/>
					</span>
					<span v-if="hasValue(item.started)" class="activity-duration">
						<NanoSince :nanotime="item.started"/>
					</span>
				</td>
				<td class="hlast narrow"
					:class="{
						'good': item.status === 'good',
						'unhappy': item.status === 'unhappy',
						'bad': item.status === 'bad',
					}"
					title="Complete"
					v-else
				>
					<i aria-hidden="true" class="material-icons" v-if="item.status === 'bad'">error</i>
					<i aria-hidden="true" class="material-icons" v-else>done</i>
					<span
						v-show="hasValue(item.duration)"
						class="activity-duration"
					>{{ item.duration }}</span>
				</td>

			</tr>
			</tbody>
		</table>
	</div>
</template>

<script>

import * as moment from 'moment'

import {mapGetters} from 'vuex'

import ProvisioningAPI from '../common/ProvisioningAPI'
import ProgressSpinner from '../material/ProgressSpinner'
import NanoSince from '../material/NanoSince'

export default {
	name: 'ActivityProgressList',
	mixins: [
		ProvisioningAPI,
	],
	components: {
		NanoSince,
		ProgressSpinner
	},
	props: [
		'configMode',
		'onlyForced', // boolean
		'filterUsername', // array of strings
		'filterServers', // array of strings
	],
	data: function () {
		return {
			pending: false,
		}
	},
	computed: {
		...mapGetters([
			'appConfig',
			'websocketConnected',
			'topicResources', // This is where you can read the topic subscriptions
		]),

		subscriptionTopics () {
			return [
				'v1-' + this.configMode,
				'v1-' + this.configMode + '-server',
			]
		},

		activityRaw () {
			let result = []
			this.subscriptionTopics.forEach(topic => {
				let servers = this.topicResources(topic)
				if (servers === undefined) {
					return
				}
				Object.values(servers).forEach(server => {
					if (server === undefined) {
						return
					}
					if (!server.type.includes(this.configMode)) {
						return
					}

					let summary = {
						id: server.id,
						type: server.type,
						modified: server.modified,
						timestamp: server.timestamp,
						name: this.nameFromHostname(server.id, false),
						pop: this.popFromHostname(server.id),
						status: server.attributes.status,
						// statusRAG: server.attributes.statusRAG,
						good: 0,
						unhappy: 0,
						bad: 0,
						completed: 0,
						pending: 0,
						total: 0,
						progress: [],
					}
					if (this.hasValue(server.attributes)) {
						if (this.hasValue(server.attributes.progress)) {
							summary.progress = server.attributes.progress
						}
					}

					if (summary.progress.length === 0) {
						return
					}

					summary.progress.forEach(item => {
						item.server = summary.name + '.' + summary.pop
						item.serverType = summary.type
						item.serverStatus = summary.status
						item.status = this.itemStatus(item)

						if (item.started > 1) {
							item.startedAge = this.secondsSinceNano(item.started)
						}
						if (item.updated > 1) {
							item.updatedAge = this.secondsSinceNano(item.updated)
						}
						if (item.finished > 1) {
							item.finishedAge = this.secondsSinceNano(item.finished)
						}

						if (item.forced) {
							if (item.username === undefined) {
								item.username = 'admin'
							}
						}

						if (item.completed) {
							summary.completed++
						} else {
							summary.pending++
						}
						summary.total++
						switch (item.status) {
						case 'good':
							summary.good++
							break
						case 'bad':
							summary.bad++
							break
						default:
							summary.unhappy++
						}
					})

					result.push(summary)

				})
			})
			return result
		},

		activityWithPending () {
			return this.activityRaw.filter(server => server.pending > 0)
		},

		activityOnlyCompleted () {
			return this.activityRaw.filter(server => server.pending === 0 && server.completed > 0)
		},

		hasActivity () {
			return this.activityRaw.length > 0
		},

		progressItems () {
			let result = []
			this.activityRaw.forEach(server => {
				let pickedItem = undefined
				server.progress.forEach(item => {

					if (this.onlyForced === true) {
						if (!item.force) {
							return
						}
					}
					if (this.hasValue(this.filterUsername) && Array.isArray(this.filterUsername) && this.filterUsername.length > 0) {
						if (!this.filterUsername.includes(item.username)) {
							return
						}
					}

					// console.debug('[DEBUG] item', item)
					if (this.hasValue(this.filterServers) && Array.isArray(this.filterServers) && this.filterServers.length > 0) {
						if (this.hasValue(item.server)) {
							if (!this.filterServers.includes(String(item.server).toLowerCase())) {
								return
							}
						}
					}

					if (item.completed) {

						if (server.pending > 0) {
							// if there is other pending activity for this server,
							//  then ignore completed activity for this server.
							return
						}

						// hide completed activity, after a short delay
						let ignoreAfterAge = 45
						if (item.status === 'good') {
							ignoreAfterAge = 10 // even shorter if it was successful
						}
						if (item.finishedAge > ignoreAfterAge) {
							return
						}

					}

					if (pickedItem === undefined) {
						pickedItem = item

					} else {
						if (item.started > pickedItem.started) {
							pickedItem = item
						}
					}

				})

				if (pickedItem !== undefined) {
					// push this to the result, so that it is shown in the table
					result.push(pickedItem)
				}
			})
			result.sort((a, b) => {
				// if (a.completed !== b.completed) {
				// 	if (a.status !== b.status) {
				// 		if (a.status === 'bad') {
				// 			return -1
				// 		}
				// 		return 1
				// 	}
				// 	if (a.completed) {
				// 		return 1
				// 	}
				// }
				if (a.started !== b.started) {
					if (a.started > b.started) {
						return -1
					}
					if (a.started < b.started) {
						return 1
					}
				}
				if (a.id > b.id) {
					return -1
				}
				if (a.id < b.id) {
					return 1
				}
				return 0
			})
			return result
		},

		hasprogressItems () {
			return this.progressItems.length > 0
		},

		// ------------------------------------------------------------

	},
	mounted () {

		// NOTE: This expects its container to do the subscribing
		//        and unsubscribing for it. This allows the data
		//        to stay loaded when this component is not.

		if (this.hasValue(this.configMode)) {
			this.filterPackages = [
				this.configMode
			]
		}

	},
	methods: {

		itemStatus (item) {
			if (item.completed !== true) {
				return 'pending'
			}
			if (String(item.message).startsWith('SUCCESS')) {
				return 'good'
			}
			if (String(item.message).includes('ERROR')) {
				return 'bad'
			}
			if (String(item.message).includes('ABORT')) {
				return 'bad'
			}
			if (String(item.message).includes('FAIL')) {
				return 'bad'
			}
			return 'unhappy'
		},

		nameFromHostname (hostname, lower) {
			if (hostname === undefined) {
				return undefined
			}
			if (hostname.includes('.')) {
				let nameParts = hostname.split('.')
				let name = nameParts[0].toLowerCase()
				if (lower === undefined || lower === false) {
					if (name.startsWith('frontend') ||
						name.startsWith('haproxy') ||
						name.startsWith('delivery') ||
						name.startsWith('storage')
					) {
						return name.slice(0, -1) + name.charAt(name.length - 1).toUpperCase()
					}
				}
				return name
			}
			return ''
		},
		popFromHostname (hostname) {
			if (hostname === undefined) {
				return undefined
			}
			let nameParts = hostname.split('.')
			if (nameParts.length > 1) {
				return nameParts[1].toUpperCase()
			} else if (nameParts.length > 0) {
				return nameParts[0].toUpperCase()
			} else {
				return ''
			}
		},

		nanoToUnix (timestamp) {
			const nanoSecondsToSeconds = ts => ts / 1000000000
			return Math.round(nanoSecondsToSeconds(timestamp))
		},
		secondsSinceNano (timestamp) {
			let now = moment.unix(moment.default().unix())
			let then = moment.unix(this.nanoToUnix(timestamp))
			return now.diff(then, 'seconds')
		},
		nanoToHuman (timestamp) {
			return moment.unix(this.nanoToUnix(timestamp)).format(
				'dddd, MMMM Do YYYY, HH:mm:ss'
			) + ' UTC'
		},
		timestampText (item) {
			if (item === undefined) {
				return ''
			}
			if (item.started !== undefined && item.started > 1) {
				return this.nanoToHuman(item.started)
			}
			if (item.timestamp !== undefined) {
				if (typeof item.timestamp === 'number') {
					return this.nanoToHuman(item.timestamp)
				}
				if (typeof item.timestamp === 'string' || item.timestamp instanceof String) {
					return moment.unix(moment.default(item.timestamp)).format(
						'dddd, MMMM Do YYYY, HH:mm:ss'
					) + ' UTC'
				}
				return item.timestamp
			}
			return ''
		},

		openDetailsDialog (entry) {
			if (entry === undefined) {
				return
			}
			console.debug(
				'[DEBUG] ConfigEvents:',
				JSON.stringify(entry, null, 2)
			)
			// let clone = Object.assign({}, entry)
			// let data = [
			// 	{title: 'Metadata', key: clone}
			// ]
			// if (clone.message !== undefined) {
			// 	data.unshift({
			// 		title: 'Message', key: clone.message
			// 	})
			// 	clone.message = undefined
			// }
			// this.$emit('request-dialog', {
			// 	dialog: 'keys',
			// 	title: 'Activity at ' + this.timestampText(clone),
			// 	data: data,
			// })

			let hostname = String(entry.server).toLowerCase() + '.cachenetworks.com'
			this.$emit('request-dialog', {
				dialog: 'activity',
				configMode: this.configMode,
				selectedServer: hostname,
				title: '',
			})
		},

	}

}

</script>

<style scoped lang="scss">

.server-real-time-data {
	display: block;
	min-height: 60vh;
	height: 100%;
	vertical-align: top;
}

.small-grid {
	padding: 0;
	margin-top: 0;
}

.pending-container {
	min-height: 10vh;
}

.pending {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 1000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.pending-message {
	margin-top: 2em;
	display: block;
	clear: both;
}


.table-container {
	display: block;
	vertical-align: top;
}

.disconnected {
	opacity: 0.8;
}

.sleepy {
	background-image: url("../../images/sleeping-dog.png");
	background-position: right bottom; /*Positioning*/
	background-repeat: no-repeat; /*Prevent showing multiple background images*/
	background-size: 10%;
}

th {
	font-weight: normal;
	text-align: left;
	vertical-align: top;
	min-width: 15%;
	max-width: 25%;
	width: 20%;
}

td {
	min-width: 25%;
	max-width: 80%;
	width: 25%;
}

.embedded-table {
	margin: 0;
	padding: 0;
	width: 100%;
	max-width: 100%;
	table-layout: fixed;
	border: 0;
	border-collapse: collapse;
	border-spacing: 0;
}

//.embedded-table tr {
//	background-color: transparent;
//}

.embedded-table th, .embedded-table td {
	padding: .5em;
	text-align: left;
	vertical-align: middle;
	line-height: 2em;
	border: 0;
	width: 49%;
	background-color: transparent;
	color: black;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.embedded-table th.wide, .embedded-table td.wide {
	min-width: 60%;
}

.embedded-table th.narrow, .embedded-table td.narrow {
	width: 20%;
}

.embedded-table th.vnarrow, .embedded-table td.vnarrow {
	width: 5%;
}

.embedded-table th {
	//font-weight: bold;
	background-color: #6200ee;
	color: white;
}

.embedded-table th {
	border-right: 1px solid darkgrey;
	border-bottom: 1px solid darkgrey;
}

.embedded-table th.hfirst, .embedded-table td.hfirst {
	border-left: 0;
}

.embedded-table th.hlast, .embedded-table td.hlast {
	border-right: 0;
}

.embedded-table td {
	border-left: 1px solid darkgrey;
	border-bottom: 1px solid darkgrey;
}

.embedded-table .last {
	border-bottom: 0;
}

.embedded-table tr {
	opacity: 0.9;
}

.embedded-table tr:hover {
	background-color: lightgoldenrodyellow;
	opacity: 1;
}

.embedded-table tr:last-of-type {
	border-bottom: 0;
}

.embedded-table tr:last-of-type th {
	border-bottom: 0;
}

.embedded-table tr:last-of-type td {
	border-bottom: 0;
}

.embedded-table th.child, .embedded-table td.child {
}

.embedded-table td.child::before {
	content: '➡️ ';
}

.material-icons {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
}

ul {
	margin: 0;
}

p {
	margin: 0 0 1em;
}

.ok {
	color: darkgreen;
}

.good {
	background-color: lightgreen;
}

.subtle-good {
	color: darkgreen !important;
}

.unhappy {
	background-color: #ffbf00;
}

.subtle-unhappy {
	color: darkorange !important;
}

.bad {
	background-color: #f18787;
}

.subtle-bad {
	color: darkred !important;
}

.warning {
	color: darkorange;
	//font-weight: bold;
}

.disabled {
	opacity: 0.5;
}

tr.disabled th {
	background-color: darkgray;
}

.none {
	font-style: italic;
	color: darkgray;
}

.yellow-star {
	color: #ffbf00;
}

.red-engineer {
	color: orangered;
}

.subtle-list {
	list-style-type: none; /* Remove bullets */
	padding: 0; /* Remove padding */
	margin: 0; /* Remove margins */
}

.activity-duration {
	padding-left: 0.25em;
}

.activity-status-message {
	text-overflow: ellipsis;
}

.float-right {
	float: right;
	padding-left: 0.5em;
}

.error-box {
	clear: both;
	border: 1px dashed red;
	border-left: 0;
	border-right: 0;
	padding: 0.5em;
	margin: 0.5em;
	background-color: rgba(245, 230, 230, 0.5);
	color: darkred;
}

pre {
	padding: 0.25em;
	margin: 0.5em 0.5em 0.5em 0;
	background-color: #eeeeee;
	border: 1px solid lightgray;
}

</style>
