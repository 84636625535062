<template>
	<div class="server-status-summary-grid">
		<HexGrid
			:items="hexItems"
			:allowSelection="false"
			:showGroupLabel="false"
			:showItemTitle="true"
			:showGroupTitle="false"
			@item-clicked="hexItemClicked"
		/>
	</div>
</template>

<script>

import {mapGetters} from 'vuex'

import ProvisioningAPI from '../common/ProvisioningAPI'
import HexGrid from '../common/HexGrid'

export default {
	name: 'ServerStatusSummaryGrid',
	mixins: [
		ProvisioningAPI,
	],
	components: {
		HexGrid
	},
	props: [
		'configMode',
	],
	data: function () {
		return {}
	},
	computed: {
		...mapGetters([
			'appConfig',
			'websocketConnected',
			'topicResources', // This is where you can read the topic subscriptions
		]),

		subscriptionTopics () {
			return [
				'v1-' + this.configMode,
				'v1-' + this.configMode + '-server',
			]
		},

		servers () {
			let result = []
			this.subscriptionTopics.forEach(topic => {
				let servers = this.topicResources(topic)
				if (servers === undefined) {
					return
				}
				Object.values(servers).forEach(server => {
					if (server === undefined) {
						return
					}
					if (!server.type.includes(this.configMode)) {
						return
					}

					let summary = {
						id: server.id,
						type: server.type,
						modified: server.modified,
						timestamp: server.timestamp,
						name: this.nameFromHostname(server.id, false),
						pop: this.popFromHostname(server.id),
						status: server.attributes.status,
						statusRAG: server.attributes.statusRAG,
						progress: false,
					}
					if (this.hasValue(server.attributes)) {
						if (this.hasValue(server.attributes.progress)) {
							let pending = 0
							server.attributes.progress.forEach(item => {
								if (!item.completed) {
									pending++
								}
							})
							if (pending > 0) {
								summary.progress = true
							}
						}
					}

					result.push(summary)
				})
			})
			return result
		},

		hexItems () {
			let result = []
			let serverTypes = []
			this.servers.forEach(server => {
				if (!serverTypes.includes(server.type)) {
					serverTypes.push(server.type)
				}
			})
			serverTypes.forEach(serverType => {
				let good = 0
				let unhappy = 0
				let bad = 0
				let disabled = 0
				let goodProgress = false
				let unhappyProgress = false
				let badProgress = false
				let disabledProgress = false
				this.servers.forEach(server => {
					switch (server.status) {
					case 'good':
						good++
						if (server.progress === true) {
							goodProgress = true
						}
						break
					case 'unhappy':
						unhappy++
						if (server.progress === true) {
							unhappyProgress = true
						}
						break
					case 'bad':
						bad++
						if (server.progress === true) {
							badProgress = true
						}
						break
					default:
						disabled++
						if (server.progress === true) {
							disabledProgress = true
						}
					}
				})
				result.push({
					id: 'disabled',
					group: serverType,
					title: disabled,
					label: 'disabled servers',
					status: 'disabled',
					progress: disabledProgress,
				})
				result.push({
					id: 'bad',
					group: serverType,
					title: bad,
					label: 'bad servers',
					status: 'bad',
					progress: badProgress,
				})
				result.push({
					id: 'unhappy',
					group: serverType,
					title: unhappy,
					label: 'unhappy servers',
					status: 'unhappy',
					progress: unhappyProgress,
				})
				result.push({
					id: 'good',
					group: serverType,
					title: good,
					label: 'good servers',
					status: 'good',
					progress: goodProgress,
				})
			})
			return result
		},

	},
	mounted () {
		// NOTE: This expects its container to do the subscribing
		//        and unsubscribing for it. This allows the data
		//        to stay loaded when this component is not.
	},
	methods: {

		topicToMode (topic) {
			let mode = topic.replace('v1-', '')
			if (mode.includes('-')) {
				mode = mode.split('-')[0]
			}
			return mode
		},

		hexItemClicked (event) {
			console.debug('[DEBUG] hexItemClicked', event)
			let mode = this.topicToMode(event.item.group)
			let status = event.item.status
			this.$nextTick(() => {
				this.$router.push({
					path: '/' + mode + '/grid/?filterStatus=' + status,
				})
				this.$nextTick(() => {
					this.$emit('request-close', true)
				})
			})
		},

		nameFromHostname (hostname, lower) {
			if (hostname === undefined) {
				return undefined
			}
			if (hostname.includes('.')) {
				let nameParts = hostname.split('.')
				let name = nameParts[0].toLowerCase()
				if (lower === undefined || lower === false) {
					if (name.startsWith('frontend') ||
						name.startsWith('haproxy') ||
						name.startsWith('delivery') ||
						name.startsWith('storage')
					) {
						return name.slice(0, -1) + name.charAt(name.length - 1).toUpperCase()
					}
				}
				return name
			}
			return ''
		},
		popFromHostname (hostname) {
			if (hostname === undefined) {
				return undefined
			}
			let nameParts = hostname.split('.')
			if (nameParts.length > 1) {
				return nameParts[1].toUpperCase()
			} else if (nameParts.length > 0) {
				return nameParts[0].toUpperCase()
			} else {
				return ''
			}
		},

	}

}

</script>

<style scoped lang="scss">

.server-status-summary-grid {
	display: block;
	height: 100%;
	vertical-align: top;
}

</style>
