<template>
	<div>
		<HexGrid
			:allow-selection="false"
			:floatMenuGenerator="floatMenuGenerator"
			:items="augmentedServers"
			:showGroupLabel="false"
			:showGroupTitle="showGroupTitles"
		/>
	</div>
</template>

<script>

//
// This is incomplete experimental code.
// It is now obsolete will be removed once its usage has been converted to group actions.
//

import {mapGetters} from 'vuex'

import ActionsBase from './ActionsBase'
import HexGrid from '../common/HexGrid'
import ProvisioningAPI from '../common/ProvisioningAPI.vue'

export default {
	name: 'MultipleReconfigure',
	extends: ActionsBase,
	mixins: [
		ProvisioningAPI
	],
	components: {
		HexGrid,
	},
	props: [
		'serverType',
		'popFilter',
	],
	data () {
		return {
			augmentedServers: [],
			rawServers: [],
			serverInterfaces: undefined,
			reactivityHint: 0,
			showGroupTitles: false,
		}
	},
	computed: {
		...mapGetters([
			'appConfig',
		]),
	},
	mounted () {
		this.$nextTick().then(() => {
			return this.loadServers()
		}).then(() => {
			return this.performReconfiguration()
		}).catch(error => {
			console.error(error)
		})
	},
	methods: {
		loadServers () {
			if (this.backgroundLoading) {
				return Promise.reject()
			}
			if (this.loading) {
				this.error = undefined
			}
			return this.callProvisioningApi(this.serverType + '/', null, true, true).then(result => {
				let {
					meta,
					data
				} = result
				if (data === undefined) {
					data = []
				} else {
					this.rawServers = this.annotateServers(data)
				}
				if (meta !== undefined && meta.interfaces !== undefined) {
					this.serverInterfaces = meta.interfaces
				} else {
					this.serverInterfaces = undefined
				}
				this.error = undefined
			}).catch(error => {
				console.error(error)
				this.error = error
				this.loading = false
			}).finally(() => {
				this.loading = false
				this.updateAugmentedServers()
			})

		},

		performReconfiguration () {
			this.showGroupTitles = false
			return new Promise((resolve, reject) => {

				const groupChangeDelay = 1000

				let spareSlots = 2
				let availableSlots = 1
				const getAvailableSlot = (bias) => {
					return new Promise(resolve => {
						const check = () => {
							if (availableSlots > 0) {
								availableSlots--
								console.debug('[DEBUG] slot acquired with bias', bias)
								resolve()
							} else {
								setTimeout(check, 100 + bias)
							}
						}
						setTimeout(check, 10 * bias)
					})
				}
				const returnSlot = () => {
					availableSlots += 1
					if (spareSlots > 0) {
						spareSlots -= 1
						availableSlots += 1
					}
					console.debug('[DEBUG] availableSlots', availableSlots)
				}

				let promises = []
				let bias = 0
				this.augmentedServers.forEach(server => {
					if (server === undefined) {
						return
					}
					if (server.group !== 'Reconfiguring') {
						return
					}
					bias += 1
					promises.push(getAvailableSlot(bias).then(() => {
						server.status = 'unhappy'
						this.updateServer(server)

						return this.$nextTick().then(() => {
							return this.reconfigureServer(server)

						}).then(() => {
							server.status = 'good'
							this.updateServer(server)
							setTimeout(() => {
								server.group = 'Successful'
								this.updateServer(server)
							}, groupChangeDelay)

						}).catch(error => {
							console.error(error)
							server.status = 'bad'
							this.updateServer(server)
							setTimeout(() => {
								server.group = 'Failed'
								this.updateServer(server)
							}, groupChangeDelay)

						}).finally(() => {
							returnSlot()
						})

					}))
				})

				Promise.all(promises).then(() => {
					this.updateAugmentedServers()
					resolve()
				}).catch(error => {
					reject(error)
				}).finally(() => {
					setTimeout(() => {
						this.showGroupTitles = true
						this.$nextTick(this.emitCompleted)
					}, groupChangeDelay + 100)
				})

			})
		},

		reconfigureServer (server) {
			return new Promise((resolve, reject) => {
				// console.debug(server.id)
				let delay = 2000 * Math.floor(Math.random() * 3)
				setTimeout(() => {
					let r = Math.floor(Math.random() * 3)
					if (r === 0) {
						console.debug(server.id, 'Reconfigure failed')
						reject('bad bad bad')
					} else {
						console.debug(server.id, 'Reconfigure succeeded')
						resolve()
					}
				}, delay)
			})
			// return this.performAction(
			// 	'nginx',
			// 	server.id,
			// 	'reconfigure',
			// 	this.loadServerData,
			// 	undefined
			// )
		},

		emitCompleted () {
			this.$emit('completed', true)
		},

		/////////////////////////////////////////////////////

		updateServer (server) {
			this.rawServers.forEach((item, index) => {
				if (item.id === server.id) {
					this.rawServers[index] = server
				}
			})
			this.updateAugmentedServers()
		},
		updateAugmentedServers () {
			this.augmentedServers = this.sortServers(
				this.filterServers(
					this.rawServers
				)
			)
		},

		filterServers (servers) {
			let result = []
			let popFilter = this.popFilter
			if (popFilter === undefined) {
				popFilter = []
			}

			servers.forEach(server => {
				if (server === undefined) {
					return
				}
				if (this.checkIfDisabled(server)) {
					return
				}

				server.hint = this.reactivityHint
				let include = false
				if (popFilter.length === 0) {
					include = true
				} else {
					if (popFilter.includes(server.pop)) {
						include = true
					}
					if (popFilter.includes(this.shortPopCode(server.pop))) {
						include = true
					}
				}
				if (include) {
					result.push(server)
				}
			})

			return result
		},

		annotateServers (servers) {
			servers.forEach(server => {
				if (server !== undefined) {

					server.status = server.attributes.status
					server.statusRAG = server.attributes.statusRAG

					server.pop = this.shortPopCode(this.popFromHostname(server.attributes.hostname.FullName))
					server.label = this.shortNameFromHostname(server.attributes.hostname.FullName)

					// if (this.checkIfDisabled(server) || this.checkIfOffline(server)) {
					if (this.checkIfDisabled(server)) {
						server.group = 'Skipped'
						server.status = 'disabled'

					} else {
						server.group = 'Reconfiguring'
						server.status = 'disabled'

					}

				}
			})
			return servers
		},
		sortServers (servers) {
			const compareSortingKeys = function (a, b) {
				if (isNaN(a) && isNaN(b)) {
					return a < b ? -1 : 1
				}
				if (isNaN(a)) {
					return -1
				}
				if (isNaN(b)) {
					return 1
				}
				return a - b
			}
			const getSortingKey = function (a) {
				return a.attributes.pop + '--' + a.id
			}
			return servers.sort(function (first, second) {
				return compareSortingKeys(getSortingKey(first), getSortingKey(second))
			})
		},

		nameFromHostname (hostname, lower) {
			if (hostname === undefined) {
				return undefined
			}
			if (hostname.includes('.')) {
				let nameParts = hostname.split('.')
				let name = nameParts[0].toLowerCase()
				if (lower === undefined || lower === false) {
					if (name.startsWith('frontend') ||
						name.startsWith('haproxy') ||
						name.startsWith('delivery') ||
						name.startsWith('storage')
					) {
						return name.slice(0, -1) + name.charAt(name.length - 1).toUpperCase()
					}
				}
				return name
			}
			return ''
		},
		popFromHostname (hostname) {
			if (hostname === undefined) {
				return undefined
			}
			let nameParts = hostname.split('.')
			if (nameParts.length > 1) {
				return nameParts[1].toUpperCase()
			} else if (nameParts.length > 0) {
				return nameParts[0].toUpperCase()
			} else {
				return ''
			}
		},
		shortPopCode (pop) {
			return pop.replace(/[0-9]/g, '')
		},
		shortNameFromHostname (hostname) {
			let name = this.nameFromHostname(hostname)
			let pop = this.popFromHostname(hostname)
			name = name.replace('frontend', 'f')
			name = name.replace('haproxy', 'h')
			name = name.replace('delivery', 'd')
			name = name.replace('storage', 's')
			name = name.replace('warmer', 'w')
			name = name.replace('logstash', 'l')
			name = name.replace('vector-ingest', 'vi')
			name = name.replace('vector-transform', 'vt')
			name = name.replace('vector', 'v')
			return name + '.' + pop
		},

		floatMenuGenerator () {
			// console.debug('floatMenuGenerator called')
			// this.actionsServer = item
			let result = []
			console.debug('floatMenuGenerator called returning length ' + result.length)
			return result
		},
	}
}

</script>

<style scoped lang="scss">

</style>
