<template>
	<div v-if="actions !== undefined">
		<div v-for="action in actions"
			 :class="{
				'action-container': true,
				'full-width': wide === true,
			}"
		>
			<button v-if="action.disabled"
					:class="{
						'mdc-button': true,
						'mdc-button--raised': !action.outline,
						'mdc-button--outlined': action.outline,
						'full-width': wide === true,
					}"
					:disabled="action.disabled"
			>
				<i aria-hidden="true" class="material-icons mdc-button__icon">{{ action.icon }}</i>
				<span class="mdc-button__label" v-if="singleWordLabels === true">{{ singleWord(action.label) }}</span>
				<span class="mdc-button__label" v-else>{{ action.label }}</span>
			</button>
			<a v-else
			   :class="{
					'mdc-button': true,
					'mdc-button--raised': !action.outline,
					'mdc-button--outlined': action.outline,
					'full-width': wide === true,
				}"
			   :disabled="action.disabled"
			   :href="action.url"
			   v-on:click.prevent="action.callback()"
			>
				<i aria-hidden="true" class="material-icons mdc-button__icon">{{ action.icon }}</i>
				<span class="mdc-button__label" v-if="singleWordLabels === true">{{ singleWord(action.label) }}</span>
				<span class="mdc-button__label" v-else>{{ action.label }}</span>
			</a>
		</div>
	</div>
</template>

<script>

// Material Design Components
// noinspection NpmUsedModulesInstalled
import autoInit from '@material/auto-init'

export default {
	name: 'ActionsButtons',
	props: [
		'title',
		'open',
		'message',
		'actions',
		'wide',
		'singleWordLabels',
	],
	mounted () {
		autoInit()  // Material Design Components
	},
	updated () {
		autoInit()  // Material Design Components
	},
	methods: {
		singleWord(label) {
			let suffix = ''
			if (label.indexOf('(') > 0) {
				suffix = ' ' + label.substring(label.indexOf('('))
			}
			if (label.includes(' ')) {
				label = label.split(' ')[0]
			}
			return label + suffix
		},
	}
}
</script>

<style lang="scss" scoped>

.action-container {
	margin: 0.5em 1em 0.5em 0; /* top, right, bottom, left */
	display: inline-block;
}

.full-width {
	width: 100%;
}

</style>
