<template>
	<div id="confirm-dialog"
		 aria-describedby="my-dialog-content"
		 aria-labelledby="my-dialog-title"
		 aria-modal="true"
		 class="mdc-dialog confirm-dialog"
		 data-mdc-auto-init="MDCDialog"
		 role="alertdialog">
		<div class="mdc-dialog__container">
			<div class="mdc-dialog__surface"><!--
            Title cannot contain leading whitespace due to mdc-typography-baseline-top()
                --><h2 id="my-dialog-title" class="mdc-dialog__title"><!--
     -->{{ dialogTitle }}<!--
   --></h2>
				<div id="my-dialog-content" class="mdc-dialog__content my-dialog-content">
					<div v-show="question !== undefined && question.length > 0">
						<p v-for="line in questionLines">{{ line }}</p>
					</div>
				</div>
				<footer class="mdc-dialog__actions">
					<button v-show="showCancel === undefined || showCancel === true"
							class="mdc-button mdc-dialog__button"
							type="button"
							v-on:click.prevent="cancel"
					>
						<span class="mdc-button__label">Cancel</span>
					</button>
					<button v-if="payingAttention" class="mdc-button mdc-dialog__button"
							type="button"
							v-on:click.prevent="accept"
					>
						<span class="mdc-button__label">OK</span>
					</button>
					<button v-if="!payingAttention" class="mdc-button mdc-dialog__button"
							type="button"
							v-on:click.prevent="checkIfPayingAttention"
					>
						<span class="mdc-button__label">I understand</span>
					</button>
				</footer>
			</div>
		</div>
		<div class="dialog-captcha hidden"></div>
		<div class="mdc-dialog__scrim"></div>
	</div>
</template>

<script>

// Material Design Components
// noinspection NpmUsedModulesInstalled
import autoInit from '@material/auto-init'

import * as ShapeCaptcha from 'shapecaptcha'

export default {
	props: [
		'open',
		'title',
		'question',
		'useCaptcha',
		'showCancel',
	],
	data: function () {
		return {
			'dialog': undefined,
			'payingAttention': false,
		}
	},
	computed: {
		isOpen () {
			return this.dialog.isOpen
		},
		dialogTitle () {
			if (this.title !== undefined && this.title !== '') {
				return this.title
			} else {
				return 'Confirm'
			}
		},
		questionLines () {
			if (this.question === undefined) {
				return undefined
			}
			return this.question.split('\n')
		}
	},
	watch: {
		open (value) {
			if (this.isOpen !== value) {
				if (value) {
					this.openDialog()
				} else {
					this.closeDialog()
				}
			}
			this.$emit('update:open', value)
			if (!value) {
				this.$emit('closed', '')
			}
		}
	},
	mounted () {
		autoInit()  // Material Design Components
		this.reset()
		this.dialog = this.$el.MDCDialog
		this.dialog.listen('MDCDialog:opened', () => {
			this.$el.setAttribute('aria-hidden', 'true')
			this.reset()
			this.$emit('update:open', true)
		})
		this.dialog.listen('MDCDialog:closed', () => {
			this.$el.removeAttribute('aria-hidden')
			this.reset()
			this.$emit('update:open', false)
		})
	},
	updated () {
		autoInit()  // Material Design Components
		this.dialog.layout()
	},
	methods: {
		openDialog () {
			if (!this.isOpen) {
				this.dialog.open()
			}
		},
		closeDialog () {
			if (this.isOpen) {
				this.dialog.close()
			}
		},
		reset () {
			this.$el.querySelector('.dialog-captcha').classList.add('hidden')
			this.$el.querySelector('.dialog-captcha').innerHTML = ''
			if (this.useCaptcha === undefined) {
				this.payingAttention = true
			} else {
				this.payingAttention = !this.useCaptcha
			}
		},
		checkIfPayingAttention () {
			this.$el.querySelector('.dialog-captcha').classList.remove('hidden')
			const sc = ShapeCaptcha.create({
				timeout: 60, // sec, timeout before the task is rejected
				items: 2,
				container: '.dialog-captcha',
				font: 'Roboto, sans-serif',
				bgColor: '#fafafa',
				drawColor: '#AAAAAA',
				acceptColor: '#00FF00',
				textColor: '#ffffff',
				textBgColor: '#505050',
				helperText: 'Just checking that you really want to do this',
				drawLineWidth: 5,
				successLineWidth: 10
			})
			sc.start()
				.then(() => {
					console.log('ShapeCaptcha test passed')
					this.payingAttention = true
					this.$el.querySelector('.dialog-captcha').classList.add('hidden')
				})
				.catch(() => {
					console.log('ShapeCaptcha test failed')
					this.reset()
				})
		},
		accept () {
			this.$emit('confirmed', '')
			this.dialog.close()
			this.reset()
		},
		cancel () {
			this.$emit('cancelled', '')
			this.dialog.close()
			this.reset()
		},
	},
}
</script>

<style lang="scss">

.dialog-captcha {
	position: fixed;
	top: 5%;
	left: 5%;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	width: 90%;
	height: 90%;
	z-index: 15;
}

.hidden {
	display: none;
	visibility: hidden;
}

</style>
