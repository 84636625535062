//
// Javascript application state management.
//
// Vuex is a state management pattern + library for Vue.js applications.
//  It serves as a centralized store for all the components in an application,
//  with rules ensuring that the state can only be mutated in a predictable
//  fashion. It also integrates with Vue's official devtools extension to
//  provide advanced features such as zero-config time-travel debugging and
//  state snapshot export / import.
//
//
//                         ┌───────────────┐
//                         │               │
//                         │  Backend API  │
//                         │               │
//                         └────▲──────────┘
//                              │
//                       ┌──────┼───────────────────────┐
//                       │      │                       │
//            Dispatch   │  ┌───▼─────┐ Commit          │
//          ┌────────────┼──► Actions ├────────┐        │
//          │            │  └─────────┘        │        │
//          │            │                     │        │
//          │            │                     │        │
// ┌────────┴─────────┐  │                     │        │  ┌──────────────┐
// │                  │  │               ┌─────▼─────┐  │  │              │
// │  Vue components  │  │               │ Mutations ◄──┼──►  Dev tools   │
// │                  │  │               └─────┬─────┘  │  │              │
// └────────▲─────────┘  │                     │        │  └──────────────┘
//          │            │                     │        │
//          │            │                     │        │
//          │            │  ┌───────┐          │        │
//          └────────────┼──┤ State ◄──────────┘        │
//            Render     │  └───────┘   Mutate          │
//                       │                              │
//                       └──────────────────────────────┘
//
// @see https://vuex.vuejs.org
// @author: Brian Wojtczak, brian.wojtczak@cachefly.com
// @copyright: Copyright 2020 by CacheNetworks, LLC. All Rights Reserved.

import Vue from 'vue'
import Vuex from 'vuex'

import createAppConfigPlugin from './appconfig'
import createSyncPlugin from './sync'
import createCachePlugin from './cache'

Vue.use(Vuex)

export const SSO_CREDENTIAL = 'SSO_CREDENTIAL'

function getEnvironment () {

	// load app preferences
	const preferencesStorageKey = 'cachefly-app-preferences'
	let appPreferences = localStorage.getItem(preferencesStorageKey)
	if (appPreferences !== null && appPreferences !== undefined && appPreferences !== '') {
		appPreferences = JSON.parse(appPreferences)
	} else {
		appPreferences = {}
	}

	// override preferences with query string
	const urlSearchParams = new URLSearchParams(window.location.search)
	let environment = urlSearchParams.get('environment')
	if (environment !== null && environment !== undefined && environment !== '') {
		appPreferences['environment'] = environment
	}

	// save preferences
	if ('environment' in appPreferences) {
		localStorage.setItem(preferencesStorageKey, JSON.stringify(appPreferences))
		return appPreferences['environment']
	}

	// fallback to NODE_ENV
	return process.env.NODE_ENV
}

export default new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production',

	// Read only data (change it via mutations, via actions)
	state: {
		ssoCredential: null,
	},

	// Read only computed state (not cached)
	getters: {
		ssoCredential: state => state.ssoCredential,
	},

	// Synchronous trackable changes to the state
	mutations: {
		[SSO_CREDENTIAL] (state, payload) {
			if (payload === null || payload === undefined) {
				state.ssoCredential = undefined
				return
			}
			state.ssoCredential = JSON.parse(JSON.stringify(payload))
		},
	},

	// [Asynchronous] actions which may change the state (via mutations)
	actions: {},

	// Special functionality
	plugins: [
		createAppConfigPlugin(
			{
				environment: getEnvironment()
			}
		),
		createSyncPlugin(),
		createCachePlugin(
			{
				name: 'provisioning-webui',
				secretToken: 'q4IMbIAzQm88EdmjXmUoSeYVcypcYekK'
			}
		),
	],

	// Extendability and Optional Namespaces
	modules: {},
})
