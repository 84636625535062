<template>
	<ul class="mdc-list mdc-list--two-line mdc-list--two-line-with-buttons"
		v-if="buttons !== undefined"
	>
		<li class="mdc-list-item with-primary-button"
			v-for="(button, index) in buttons"
			:key="index"
		>
			<span class="mdc-list-item__ripple"></span>
			<span class="mdc-list-item__text">
		      <span class="mdc-list-item__primary-text"
			  >
				  <button :class="{
					  'mdc-button': true,
					  'mdc-button--raised': !button.outlined,
					  'mdc-button--outlined': button.outlined,
				  }"
						  v-if="button.title !== undefined"
						  @click="button.callback"
				  >
				    <span class="mdc-button__ripple"></span>
				    <span class="mdc-button__label">{{ button.title }}</span>
				  </button>
				  <button class="mdc-button mdc-button--raised"
						  v-if="button.title2 !== undefined"
						  @click="button.callback2"
				  >
				    <span class="mdc-button__ripple"></span>
				    <span class="mdc-button__label">{{ button.title2 }}</span>
				  </button>
		      </span>
		      <span class="mdc-list-item__secondary-text">{{ button.helpText }}</span>
		    </span>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'ButtonsList',
	props: {
		buttons: {
			type: Array,
			required: true,
		},
	},
	mounted: () => {
	},
}
</script>

<style scoped lang="scss">

.mdc-list--two-line-with-buttons {

	.mdc-list-item {
		height: 92px;
	}

	.mdc-list-item__primary-text {
		height: 72px;
	}

	.mdc-button {
		margin-right: 1em;
	}

}

</style>
