<template>
	<div>
		<!-- This template corresponds to the mixin functionality -->
		<div>
			<component
				:is="interactiveComponent"
				:actions="actions"
				:message="dialogMessage"
				:open.sync="dialogOpen"
				:pending="actionPending"
				:title="dialogTitle"
				@actions-callback="$emit('actions-callback', $event)"

			/>
		</div>
		<div class="actions">
			<ActivityDialog
				v-bind:open.sync="activityDialogOpen"
				v-bind:add-close="activityDialogAddClose"
				v-bind:selected-server="activityDialogSelectedServer"
				v-bind:config-mode="activityDialogConfigMode"
				v-bind:title="keysTitle"
				v-bind:message="activityDialogMessage"
				v-on:closed="activityDialogClosed"
			/>
		</div>
		<div class="actions">
			<DisableDialog
				v-bind:open.sync="disableDialogOpen"
				v-bind:server-name="disableDialogServerName"
				v-bind:save-status="disableDialogSaveStatus"
				v-bind:save-action="performDisablementSaveAction"
			/>
		</div>
		<div class="actions">
			<KeysDialog
				v-bind:keysdata="keysData"
				v-bind:open.sync="keysOpen"
				v-bind:showclose="keysShowClose"
				v-bind:title="keysTitle"
				v-on:closed="keysDialogClosed"
			/>
		</div>
		<div class="actions">
			<ConfirmDialog
				v-bind:open.sync="confirmDialogOpen"
				v-bind:question="confirmQuestion"
				v-bind:title="confirmTitle"
				v-on:cancelled="confirmDialogCancelled"
				v-on:closed="confirmDialogClosed"
				v-on:confirmed="confirmDialogConfirmed"
			/>
		</div>
	</div>
</template>

<script>

import ActionsBase from './ActionsBase'
import ActionsUIMixin from './ActionsUI'
import ActivityDialog from '../servers/ActivityDialog'
import ConfirmDialog from '../common/ConfirmDialog'
import DisableDialog from './DisableDialog.vue'
import KeysDialog from '../common/KeysDialog'

const ucFirst = string => {
	if (string === undefined || string === null) {
		return ''
	}
	return string.charAt(0).toUpperCase() + string.slice(1)
}

export default {
	name: 'Actions',
	extends: ActionsBase,
	mixins: [
		ActionsUIMixin
	],
	components: {
		ActivityDialog,
		ConfirmDialog,
		DisableDialog,
		KeysDialog,
	},
	props: [
		'server',
		'serverName',
		// 'serverType',
		'agent',
		'agentFocus',
		'activeInterfaces',
		'refreshCallback',
		'detailsUrlIcon',
		'detailsUrlLabel',
		'request',
	],
	data: function () {
		return {}
	},
	computed: {

		actions () {
			let detailsUrl = undefined
			if (this.server !== undefined) {
				detailsUrl = this.server.detailsUrl
			} else if (this.agent !== undefined) {
				detailsUrl = this.agent.detailsUrl
			}
			return this.availableActions(
				(server, agent, action) => {
					this.requestActionByName(action)
				},
				this.server,
				this.agent,
				this.agentFocus,
				this.activeInterfaces, // interfaces
				detailsUrl,
				this.detailsUrlIcon,
				this.detailsUrlLabel,
				this.refreshCallback !== undefined,
				this.actionPending
			)
		},
		dialogTitle () {
			let agent = this.agent
			if (this.agentFocus && agent === undefined) {
				agent = this.server
			}
			return this.getDialogTitle(
				this.serverType,
				this.server,
				agent
			)
		},
		dialogMessage () {
			let agent = this.agent
			if (this.agentFocus && agent === undefined) {
				agent = this.server
			}
			return this.getDialogMessage(
				this.serverType,
				this.server,
				agent,
				this.agentFocus
			)
		}

	},
	watch: {
		actionPending (value) {
			if (this.server !== undefined) {
				this.server.pending = value
			}
			if (this.agent !== undefined) {
				this.agent.pending = value
			}
		},
		request (value) {
			if (value !== undefined) {
				this.requestChanged(value)
			}
		}
	},
	mounted () {
	},
	methods: {

		////////////////////////////////////////////////////////////////////////

		// Allows for an action to be triggered through a prop
		requestChanged (value) {
			if (typeof value === 'object' && value !== null) {
				this.requestActionByName(value.action)
			}
		},

		// Allows for an action to be triggered by name
		requestActionByName (action) {
			switch (action) {
			case 'ping':
				this.requestPing()
				break
			case 'activity-dialog':
				let serverId = this.serverName
				if (this.server !== undefined) {
					serverId = this.server.id
				}
				let serverType = this.serverType
				if (this.agentFocus) {
					serverType = 'agent'
				}
				this.openActivityDialog(serverId, serverType, undefined)
				break
			case 'generate-and-store':
				this.requestGenerateAndStore()
				break
			case 'apply-stored':
				this.requestApplyStored()
				break
			case 'reconfigure':
				this.requestReconfigure()
				break
			case 'validate':
				this.requestValidate()
				break
			case 'reload':
				this.requestReload()
				break
			case 'verify':
				this.requestVerify()
				break
			case 'disable':
				this.requestDisable()
				break
			case 'enable':
				this.requestEnable()
				break
			case 'delete':
				this.requestDelete()
				break
			case 'details':
				this.navigateToDetails()
				break
			case 'refresh':
				this.performRefresh()
				break
			case 'sleep':
				this.requestSleep()
				break
			case 'exit':
				this.requestExit()
				break
			case 'approve':
				this.requestApprove()
				break
			case 'deregister':
				this.requestDeregister()
				break
			case 'update':
				this.requestUpdate()
				break
			}
		},

		////////////////////////////////////////////////////////////////////////

		// Navigate method triggered by user interaction
		navigateToDetails () {
			if (this.server !== undefined) {
				this.$router.push({path: this.server.detailsUrl})
			} else if (this.agent !== undefined) {
				this.$router.push({path: this.agent.detailsUrl})
			}
		},

		////////////////////////////////////////////////////////////////////////

		// Attempt to refresh the currently loaded server data; triggered by user interaction
		performRefresh () {
			if (this.refreshCallback !== undefined) {
				this.setActionPending()
				this.refreshCallback().finally(this.clearActionPending)
			}
		},

		// Request Ping method triggered by user interaction
		requestPing () {
			this.performActionWithUI(
				'ping',
				'Ping Agent from API',
				'Requesting action, please wait ...'
			)
		},

		// Request Generate And Store method triggered by user interaction
		requestGenerateAndStore () {
			this.requestActionWithActivityDialog(
				'generate-and-store',
				'Generate and store new ' + this.serverType + ' configuration?\n(It can/will be applied to the server later)',
				'Force Generate and Store',
				'Generating new configuration, please wait ...'
			)
		},

		// Request Apply Stored method triggered by user interaction
		requestApplyStored () {
			this.requestActionWithActivityDialog(
				'apply-stored',
				'Apply latest stored ' + this.serverType + ' configuration to the server?\nThis will force a reload.',
				'Force Apply Stored',
				'Applying, please wait ...'
			)
		},

		// Request Reconfigure method triggered by user interaction
		requestReconfigure () {
			this.requestActionWithActivityDialog(
				'reconfigure',
				'Generate new ' + this.serverType + ' configuration and immediately apply it?\nThis will force a reload.',
				'Force reconfigure ' + this.serverType,
				'Forced reconfiguration in progress, please wait ...'
			)
		},

		// Request Validate method triggered by user interaction
		requestValidate () {
			this.requestActionWithActivityDialog(
				'validate',
				'Force validation of ' + this.serverType + ' configuration?',
				'Validate ' + this.serverType + ' configuration',
				'Forced validation in progress, please wait ...'
			)
		},

		// Request Reload method triggered by user interaction
		requestReload () {
			this.requestActionWithActivityDialog(
				'reload',
				'Force reload ' + this.serverType + ' configuration?',
				'Reload ' + this.serverType + ' configuration',
				'Forced reload in progress, please wait ...'
			)
		},

		// Request Verify method triggered by user interaction
		requestVerify () {
			this.requestActionWithActivityDialog(
				'verify',
				'Verify ' + this.serverType + ' configuration?',
				'Verify ' + this.serverType + ' configuration',
				'Verify in progress, please wait ...'
			)
		},

		// Request enabling the agent triggered by user interaction
		requestEnable () {
			if (this.agentFocus) {
				this.requestActionWithUI(
					'enable',
					'Enable agent health checks?',
					'Enable agent',
					'Enabling agent, please wait ...'
				)
			} else {
				this.requestActionWithUI(
					'enable',
					'Enable ' + this.serverType + ' configuration mode and health checks?',
					'Enable ' + this.serverType + ' Server',
					'Enabling server, please wait ...'
				)
			}
		},

		// Request disabling the agent triggered by user interaction
		requestDisable () {
			this.requestDisableWithUIDialog( // Disable has a unique disablement dialog
				this.serverName,
				[this.serverType]
			)
		},

		// Request deleting the server triggered by user interaction
		requestDelete () {
			this.requestActionWithUI(
				'delete',
				'Do you wish to delete server from the API?' +
				'(this is a destructive action - be sure you understand what this means before you confirm)',
				'Delete ' + this.serverType + ' Server',
				'Deleting server from the API, please wait ...'
			)
		},

		// Request agent be instructed to sleep; triggered by user interaction
		requestSleep () {
			this.requestActionWithUI(
				'sleep',
				'Instruct agent to disconnect and sleep for 5 minutes? (it will automatically reconnect when it wakes up)',
				'Instruct Agent to Sleep for 5 minutes',
				'Sending sleep instruction, please wait ...'
			)
		},

		// Request agent be instructed to exit; triggered by user interaction
		requestExit () {
			this.requestActionWithUI(
				'exit',
				'Instruct agent to exit? (it will need to be restarted)',
				'Instruct Agent to Exit',
				'Sending exit instruction, please wait ...'
			)
		},

		// Request API approve agent's registration request; triggered by user interaction
		requestApprove () {
			this.requestActionWithUI(
				'approve',
				'Approve this agent registration? Do you trust it?',
				'Approve Agent Registration',
				'Approving agent registration, please wait ...'
			)
		},

		// Request API deregister the agent; triggered by user interaction
		requestDeregister () {
			this.requestActionWithUI(
				'deregister',
				'Deregister agent from the API? (this is a destructive action and someone will need to intervene to allow the agent to reconnect - be sure you understand what this means before you confirm)',
				'Deregister agent from API',
				'Deleting agent registration, please wait ...'
			)
		},

		// Request agent be instructed to update; triggered by user interaction
		requestUpdate () {
			const title = 'Update agent to latest available version'
			const pendingMessage = 'Installing agent update, please wait ...'
			this.requestActionWithUI('update', title + '?', title, pendingMessage)
		},

		////////////////////////////////////////////////////////////////////////

		requestActionWithUI (action, question, title, pendingMessage, additional = {}) {
			this.setActionPending()
			let serverId = this.serverName
			if (this.server !== undefined) {
				serverId = this.server.id
			}
			this.openConfirmDialog(
				serverId,
				question,
				() => this.performActionWithUI(action, title, pendingMessage, additional),
				this.clearActionPending
			)
		},

		performActionWithUI (action, title, pendingMessage, additional = {}) {
			if (pendingMessage === undefined) {
				pendingMessage = 'Please wait ...'
			}
			let serverId = this.serverName
			if (this.server !== undefined) {
				serverId = this.server.id
			}
			let serverType = this.serverType
			if (this.agentFocus) {
				serverType = 'agent'
			}
			this.setActionPending()
			this.openKeysDialogSimple(title, serverId, pendingMessage, true)
			this.performActionWithUICallbacks(
				serverType,
				serverId,
				action,
				true, // animate dots
				this.performAction, // perform
				this.refreshCallback, // refresh
				this.updateKeysDialog, // update
				this.clearActionPending, // completed
				additional, // additional fields to add to API request
			)
		},

		requestActionWithActivityDialog (action, question, title, pendingMessage, additional = {}) {
			this.setActionPending()
			let serverId = this.serverName
			if (this.server !== undefined) {
				serverId = this.server.id
			}
			this.openConfirmDialog(
				serverId,
				question,
				() => this.performActionWithActivityDialog(action, title, pendingMessage, additional),
				this.clearActionPending
			)
		},

		performActionWithActivityDialog (action, title, pendingMessage, additional = {}) {
			if (pendingMessage === undefined) {
				pendingMessage = 'Please wait ...'
			}
			let serverId = this.serverName
			if (this.server !== undefined) {
				serverId = this.server.id
			}
			let serverType = this.serverType
			if (this.agentFocus) {
				serverType = 'agent'
			}
			this.setActionPending()
			this.openKeysDialogSimple(title, serverId, pendingMessage, true)
			this.performActionWithUICallbacks(
				serverType,
				serverId,
				action,
				false, // animate dots
				this.performAction, // perform
				undefined, // refresh
				(message, modal) => { // update
					console.debug('[DEBUG] Actions: performActionWithActivityDialog: update: message=' + message + ', modal=' + modal)
					if (this.keysOpen) {
						this.openActivityDialog(serverId, serverType, message)
					} else {
						this.updateActivityDialog(message, modal)
					}

				}, // update
				() => { // completed
					this.updateActivityDialog(undefined, false)

				}, // completed
				additional, // additional fields to add to API request
			)
		},

		////////////////////////////////////////////////////////////////////////

		getDialogTitle (serverType, server, agent) {
			if (this.mode === 'menu') {
				if (serverType !== undefined && serverType.length > 1) {
					return ucFirst(serverType) + ' Actions'
				}
				return 'Actions'
			}
			if (server !== undefined) {
				return serverType + ' - ' + server.id
			}
			if (agent !== undefined) {
				return 'agent - ' + agent.label
			}
			return undefined
		},
		getDialogMessage (serverType, server, agent, agentFocus) {
			if (server === undefined) {
				return undefined
			}
			if (this.mode !== undefined && this.mode !== 'dialog') {
				return undefined
			}
			let message = ''
			if (agent !== undefined) {
				if (agent.problems !== undefined && agent.problems.length > 0) {
					agent.problems.forEach(problem => {
						switch (problem.status) {
						case 'bad':
							message += 'ERROR: ' + problem.problem + '\n'
							break

						case 'unhappy':
							message += 'WARNING: ' + problem.problem + '\n'
							break
						}
					})
					return message
				}
			}
			if (this.checkIfDisabled(server)) {
				message = 'Functionality and health checks are disabled.'
				if (!this.checkIfOffline(server, agent)) {
					message += '\nWARNING: Agent is connected despite disabled status.'
				} else {
					message += '\nAgent is not connected.'
				}

			} else if (this.checkIfOffline(server, agent)) {
				if (agentFocus) {
					message = 'Agent is not connected.'
				} else {
					message = 'Agent is not connected - remote control is not available.'
				}

			} else if (server.attributes.reload !== undefined && server.attributes.reload.failed) {
				message = 'ERROR: CONFIG RELOAD FAILED'

			} else {
				if (server.status !== undefined && server.status !== 'good') {
					message = 'This server is having issues. Please investigate.'
					if (server.status === 'unhappy') {
						message += ' (amber status)'
					}
				}
			}
			if (agentFocus) {
				if (agent !== undefined && agent.attributes !== undefined) {
					message += '\nAgent version: ' + agent.attributes.version.version
				}
			}
			return message
		}

	},
}

</script>

<style lang="scss" scoped>

.actions {
	text-align: left;
}

</style>
