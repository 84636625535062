<template>
	<div class="server-real-time-data">
		<p v-show="!hasEvents" class="mdc-typography--body1 none">
			No events data available.
		</p>
		<table class="embedded-table mdc-typography--body1">
			<thead v-if="false">
			<tr>
				<th :class="{
								'hfirst': true,
								'vnarrow': true,
							}"
					v-show="filterPackages.length === 0"
				>Package
				</th>
				<th :class="{
								'hfirst': filterPackages.length > 0,
								'vnarrow': true,
							}"
				>State
				</th>
				<th class="wide hlast">Message</th>
			</tr>
			</thead>
			<tbody v-show="hasEvents">
			<tr v-for="(event, index) in eventsOrdered"
				:key="index"
				:class="{
					'good': isGood(event),
					'unhappy': isUnhappy(event),
					'bad': isBad(event)
				}"
				@click="openDetailsDialog(event)"
			>
				<td class="hfirst vnarrow"
					:class="{
						'good': isGood(event),
						'unhappy': isUnhappy(event),
						'bad': isBad(event)
					}"
					v-show="filterPackages.length === 0"
				>
					{{ event.package }}
				</td>
				<td class="vnarrow"
					:class="{
						'hfirst': filterPackages.length > 0,
						'good': isGood(event),
						'unhappy': isUnhappy(event),
						'bad': isBad(event)
					}"
					:title="event.state"
				>
					{{ event.state }}
				</td>
				<td class="wide hlast"
					:class="{
						'good': isGood(event),
						'unhappy': isUnhappy(event),
						'bad': isBad(event)
					}"
					:title="event.message"
				>
					<span v-show="event.forced || event.force" class="float-right red-engineer">
						<i class="inline-icon material-icons red-engineer"
						   :title="'Forced by ' + event.username"
						>engineering</i> &nbsp;
					</span>

					{{ event.message }}
				</td>
			</tr>
			</tbody>
		</table>
	</div>
</template>

<script>

import * as moment from 'moment'

import {mapGetters} from 'vuex'

import ProvisioningAPI from '../common/ProvisioningAPI'

export default {
	name: 'ConfigEventsList',
	mixins: [
		ProvisioningAPI,
	],
	components: {},
	props: [
		'configMode',
	],
	data: function () {
		return {
			filterPackages: [],
			filterKinds: [
				'configuration'
			],
		}
	},
	watch: {
		configMode: function (value) {
			let filterPackages = []
			if (this.hasValue(value)) {
				filterPackages.push(value)
			}
			this.filterPackages = filterPackages
		},
	},
	computed: {
		...mapGetters([
			'appConfig',
			'websocketConnected',
			'recentEvents',   // This is where you can read the events subscriptions
		]),

		eventsFiltered () {
			let events = this.recentEvents.slice()
			events = events.filter(event => event.message !== undefined && event.message.length > 0)
			if (this.filterPackages.length > 0) {
				events = events.filter(event => event.package === undefined || this.filterPackages.includes(event.package))
			}
			if (this.filterKinds.length > 0) {
				events = events.filter(event => event.kind === undefined || this.filterKinds.includes(event.kind))
			}
			return events
		},

		eventsOrdered () {
			let events = this.eventsFiltered
			events.sort((a, b) => {
				if (a.timestamp > b.timestamp) {
					return -1
				}
				if (a.timestamp < b.timestamp) {
					return 1
				}
				return 0
			})
			return events
		},

		hasEvents () {
			return this.eventsOrdered.length > 0
		},

	},
	mounted () {

		// NOTE: This expects its container to do the subscribing
		//        and unsubscribing for it. This allows the data
		//        to stay loaded when this component is not.

		if (this.hasValue(this.configMode)) {
			this.filterPackages = [
				this.configMode
			]
		}

	},
	methods: {

		isGood (item) {
			const goodStates = ['good', 'completed', 'success']
			return goodStates.includes(item.state)
		},
		isUnhappy (item) {
			const unhappyStates = ['unhappy']
			return unhappyStates.includes(item.state)
		},
		isBad (item) {
			const badStates = ['bad', 'error', 'failed']
			return badStates.includes(item.state)
		},

		nanoToUnix (timestamp) {
			const nanoSecondsToSeconds = ts => ts / 1000000000
			return Math.round(nanoSecondsToSeconds(timestamp))
		},
		nanoToHuman (timestamp) {
			return moment.unix(this.nanoToUnix(timestamp)).format(
				'dddd, MMMM Do YYYY, HH:mm:ss'
			) + ' UTC'
		},
		timestampText (item) {
			if (item === undefined) {
				return ''
			}
			if (item.started !== undefined && item.started > 1) {
				return this.nanoToHuman(item.started)
			}
			if (item.timestamp !== undefined) {
				if (typeof item.timestamp === 'number') {
					return this.nanoToHuman(item.timestamp)
				}
				if (typeof item.timestamp === 'string' || item.timestamp instanceof String) {
					return moment.unix(moment.default(item.timestamp)).format(
						'dddd, MMMM Do YYYY, HH:mm:ss'
					) + ' UTC'
				}
				return item.timestamp
			}
			return ''
		},

		openDetailsDialog (entry) {
			if (entry === undefined) {
				return
			}
			console.debug(
				'[DEBUG] ConfigEvents:',
				JSON.stringify(entry, null, 2)
			)
			let clone = Object.assign({}, entry)
			let data = [
				{title: 'Metadata', key: clone}
			]
			if (clone.message !== undefined) {
				data.unshift({
					title: 'Message', key: clone.message
				})
				clone.message = undefined
			}
			this.$emit('request-dialog', {
				dialog: 'keys',
				title: 'Config Event at ' + this.timestampText(clone),
				data: data,
			})
		},

	}

}

</script>

<style scoped lang="scss">

.server-real-time-data {
	display: block;
	min-height: 60vh;
	height: 100%;
	vertical-align: top;
}

.small-grid {
	padding: 0;
	margin-top: 0;
}

.pending-container {
	min-height: 10vh;
}

.pending {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 1000;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.pending-message {
	margin-top: 2em;
	display: block;
	clear: both;
}


.table-container {
	display: block;
	vertical-align: top;
}

.disconnected {
	opacity: 0.8;
}

.sleepy {
	background-image: url("../../images/sleeping-dog.png");
	background-position: right bottom; /*Positioning*/
	background-repeat: no-repeat; /*Prevent showing multiple background images*/
	background-size: 10%;
}

th {
	font-weight: normal;
	text-align: left;
	vertical-align: top;
	min-width: 15%;
	max-width: 25%;
	width: 20%;
}

td {
	min-width: 25%;
	max-width: 80%;
	width: 25%;
}

.embedded-table {
	margin: 0;
	padding: 0;
	width: 100%;
	max-width: 100%;
	table-layout: fixed;
	border: 0;
	border-collapse: collapse;
	border-spacing: 0;
}

//.embedded-table tr {
//	background-color: transparent;
//}

.embedded-table th, .embedded-table td {
	padding: .5em;
	text-align: left;
	vertical-align: middle;
	line-height: 2em;
	border: 0;
	width: 49%;
	background-color: transparent;
	color: black;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.embedded-table th.wide, .embedded-table td.wide {
	min-width: 60%;
}

.embedded-table th.narrow, .embedded-table td.narrow {
	width: 20%;
}

.embedded-table th.vnarrow, .embedded-table td.vnarrow {
	width: 5%;
}

.embedded-table th {
	//font-weight: bold;
	background-color: #6200ee;
	color: white;
}

.embedded-table th {
	border-right: 1px solid darkgrey;
	border-bottom: 1px solid darkgrey;
}

.embedded-table th.hfirst, .embedded-table td.hfirst {
	border-left: 0;
}

.embedded-table th.hlast, .embedded-table td.hlast {
	border-right: 0;
}

.embedded-table td {
	border-left: 1px solid darkgrey;
	border-bottom: 1px solid darkgrey;
}

.embedded-table .last {
	border-bottom: 0;
}

.embedded-table tr {
	opacity: 0.9;
}

.embedded-table tr:hover {
	background-color: lightgoldenrodyellow;
	opacity: 1;
}

.embedded-table tr:last-of-type {
	border-bottom: 0;
}

.embedded-table tr:last-of-type th {
	border-bottom: 0;
}

.embedded-table tr:last-of-type td {
	border-bottom: 0;
}

.embedded-table th.child, .embedded-table td.child {
}

.embedded-table td.child::before {
	content: '➡️ ';
}

.material-icons {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
}

ul {
	margin: 0;
}

p {
	margin: 0 0 1em;
}

.ok {
	color: darkgreen;
}

.good {
	background-color: lightgreen;
}

.embedded-table td.good {
	color: darkgreen !important;
}

.unhappy {
	background-color: #ffbf00;
}

.embedded-table td.unhappy {
	color: darkorange !important;
}

.bad {
	background-color: #f18787;
}

.embedded-table td.bad {
	color: darkred !important;
}

.warning {
	color: darkorange;
	//font-weight: bold;
}

.disabled {
	opacity: 0.5;
}

tr.disabled th {
	background-color: darkgray;
}

.none {
	font-style: italic;
	color: darkgray;
}

.yellow-star {
	color: #ffbf00;
}

.red-engineer {
	color: orangered;
}

.subtle-list {
	list-style-type: none; /* Remove bullets */
	padding: 0; /* Remove padding */
	margin: 0; /* Remove margins */
}

.activity-duration {
	padding-left: 0.25em;
}

.activity-status-message {
	text-overflow: ellipsis;
}

.float-right {
	float: right;
	padding-left: 0.5em;
}

.error-box {
	clear: both;
	border: 1px dashed red;
	border-left: 0;
	border-right: 0;
	padding: 0.5em;
	margin: 0.5em;
	background-color: rgba(245, 230, 230, 0.5);
	color: darkred;
}

pre {
	padding: 0.25em;
	margin: 0.5em 0.5em 0.5em 0;
	background-color: #eeeeee;
	border: 1px solid lightgray;
}

</style>
