<template>
	<div>
		<!--
			 This template is not used (because this is a mixin).
			 When using this mixin, add the following to your component's template:
			 -->
		<div>
			<ConfirmDialog
				v-bind:open="confirmDialogOpen"
				v-bind:question="confirmQuestion"
				v-bind:showCancel="confirmShowCancel"
				v-bind:title="confirmTitle"
				v-bind:useCaptcha="confirmUseCaptcha"
				v-on:cancelled="confirmDialogCancelled"
				v-on:closed="confirmDialogClosed"
				v-on:confirmed="confirmDialogConfirmed"
			/>
		</div>
	</div>
</template>

<script>

/* eslint-disable vue/no-unused-components */

import ConfirmDialog from './ConfirmDialog'

export default {
	name: 'ConfirmDialogMixin',
	components: {
		ConfirmDialog,
	},
	// props: [], // Don't define props in mixins.
	data () {
		return {
			confirmAction: undefined, // callback for OK
			confirmCancelAction: undefined, // callback for CANCEL
			confirmCloseAction: undefined, // callback for CLOSE
			confirmTitle: 'Confirm',
			confirmQuestion: '',
			confirmDialogOpen: false,
			confirmConfirmed: false,
			confirmCancelled: false,
			confirmShowCancel: true,
			confirmUseCaptcha: false,
		}
	},
	methods: {

		openConfirmDialog (title, question, confirmAction, cancelAction) {
			this.confirmTitle = title
			this.confirmQuestion = question
			this.confirmAction = confirmAction
			this.confirmCancelAction = cancelAction
			this.confirmConfirmed = false
			this.confirmCancelled = false
			this.confirmShowCancel = true
			this.confirmDialogOpen = true
		},
		confirmDialogConfirmed (event) {
			this.confirmConfirmed = true
			if (this.confirmAction !== undefined) {
				this.confirmAction(event)
			}
			this.confirmAction = undefined
			this.confirmCancelAction = undefined
			this.confirmTitle = ''
			this.confirmQuestion = ''
		},
		confirmDialogCancelled () {
			this.confirmCancelled = true
			if (this.confirmCancelAction !== undefined) {
				this.confirmCancelAction()
			}
			this.confirmAction = undefined
			this.confirmCancelAction = undefined
			this.confirmTitle = ''
			this.confirmQuestion = ''
		},
		confirmDialogClosed () {
			// if (!this.confirmConfirmed && !this.confirmCancelled) {
			// 	this.confirmDialogCancelled()
			// }
			if (this.confirmCloseAction !== undefined) {
				this.confirmCloseAction()
			}
		},

		resetConfirmDialog () {
			this.confirmAction = undefined
			this.confirmCancelAction = undefined
			this.confirmCloseAction = undefined
			this.confirmTitle = 'Confirm'
			this.confirmQuestion = ''
			this.confirmDialogOpen = false
			this.confirmConfirmed = false
			this.confirmCancelled = false
			this.confirmShowCancel = true
			this.confirmUseCaptcha = false
		},

	},
}

</script>
