<template>
	<div>
		<!-- This template corresponds to the mixin functionality -->
		<div>
			<component
				:is="interactiveComponent"
				:actions="actions"
				:message="dialogMessage"
				:open.sync="dialogOpen"
				:pending="actionPending"
				:title="dialogTitle"
				@update:open="dialogOpen = $event"
			/>
		</div>
		<div class="actions">
			<PageDialog
				:min-height="45"
				:max-height="85"
				:width="75"
				:open.sync="hexOpen"
				:title="hexTitle"
				:addClose="hexShowClose"
				@closed="hexDialogClosed"
			>
				<div>
					<p class="mdc-typography--body2">
						NB. These colours indicate the status of the action (not the status of the server).
					</p>
				</div>
				<div v-if="hexOpen" v-show="hexOpen && websocketConnected">
					<!-- BEGIN GRID -->
					<div class="mdc-layout-grid small-grid">
						<div class="mdc-layout-grid__inner form-grid-inner">
							<div :class="mdcGridCellClasses">
								<HexGrid
									:items="hexItems"
									:allowSelection="false"
									:showGroupLabel="false"
									:showItemTitle="true"
									:showGroupTitle="false"
									@item-clicked="hexItemClicked"
								/>
							</div>
							<div :class="mdcGridCellClasses">
								<ActivityProgressList
									:configMode="serverType"
									:filterServers="activityFilterServers"
									@request-dialog="requestDialog($event)"
								/>
							</div>
						</div>
					</div>
					<!-- END GRID -->
				</div>
			</PageDialog>
		</div>
		<div class="actions">
			<KeysDialog
				:keysdata="keysData"
				:open.sync="keysOpen"
				:showclose="keysShowClose"
				:title="keysTitle"
				@closed="keysDialogClosed"
			/>
		</div>
		<div class="actions">
			<ConfirmDialog
				:open.sync="confirmDialogOpen"
				:question="confirmQuestion"
				:title="confirmTitle"
				@cancelled="confirmDialogCancelled"
				@closed="confirmDialogClosed"
				@confirmed="confirmDialogConfirmed"
			/>
		</div>
		<div>
			<Actions
				:activeInterfaces="activeInterfaces"
				:agent="singularAgent"
				:agentFocus="agentFocus"
				:detailsUrlIcon="detailsUrlIcon"
				:detailsUrlLabel="detailsUrlLabel"
				:open="singularOpen"
				:request="singularRequest"
				:server="singularServer"
				:server-type="serverType"
				mode="dialog"
			/>
		</div>
	</div>
</template>

<script>

import {mapGetters} from 'vuex'

import ActionsBase from './ActionsBase'
import ActionsUIMixin from './ActionsUI'
import ActivityProgressList from './ActivityProgressList'
import ConfirmDialog from '../common/ConfirmDialog'
import KeysDialog from '../common/KeysDialog'
import Actions from './Actions'
import MultipleReconfigure from './MultipleReconfigure.vue'
import PageDialog from '../common/PageDialog.vue'
import HexGrid from '../common/HexGrid.vue'
import ServerStatusSummaryGrid from './ServerStatusSummaryGrid.vue'
import ConfigEventsList from './ConfigEventsList.vue'

const mdcGridCellClasses = 'mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-6-desktop'

export default {
	name: 'GroupActions',
	extends: ActionsBase,
	mixins: [
		ActionsUIMixin
	],
	components: {
		ConfigEventsList, ServerStatusSummaryGrid,
		ActivityProgressList,
		HexGrid,
		PageDialog, MultipleReconfigure,
		Actions, // the singular version
		KeysDialog,
		ConfirmDialog,
	},
	props: [

		// list of servers to act on
		'servers',

		// list of agents to act on
		// 'agents',

		// agentFocus is true if we wish to act against the agent (not the server)
		'agentFocus',

		// the active interfaces for the given server type
		'activeInterfaces',

		// a callback which refreshes the server data
		'refreshCallback',

		// the action to perform
		'request',

		// the icon used for the details url
		'detailsUrlIcon',

		// the label used for the details url
		'detailsUrlLabel',
	],
	data: function () {
		return {
			singularServer: undefined,
			singularAgent: undefined,
			singularRequest: undefined,
			singularOpen: false,

			actionsRefreshHint: 0,
			actionsRefreshInterval: undefined,
			groupResults: []
		}
	},
	computed: {
		...mapGetters([
			'websocketConnected',
		]),

		mdcGridCellClasses () {
			return mdcGridCellClasses
		},

		actions () {
			// this hint causes vue to update this value on a timer as well
			// as when our source data changes - we do this to reflect deep
			// changes which vue's reactivity can't normally watch.
			let usefulHint = this.actionsRefreshHint
			usefulHint++

			let potentialActions = []
			if (this.servers !== undefined) {
				this.servers.forEach(server => {
					this.availableActions(
						this.singularActionCallback,
						server,
						undefined,
						this.agentFocus,
						this.activeInterfaces, // interfaces
						undefined,
						undefined,
						undefined,
						this.refreshCallback !== undefined,
						this.actionPending
					).forEach(action => {
						potentialActions.push(action)
					})
				})
			}
			// if (this.agents !== undefined) {
			// 	this.agents.forEach(agent => {
			// 		const actions = this.availableActions(
			// 			this.singularActionCallback,
			// 			undefined,
			// 			agent,
			// 			true,
			// 			undefined,
			// 			undefined,
			// 			undefined,
			// 			this.refreshCallback !== undefined,
			// 			this.actionPending
			// 		)
			// 		// console.debug('agent actions', agent, actions)
			// 		actions.forEach(action => {
			// 			potentialActions.push(action)
			// 		})
			// 	})
			// }
			potentialActions = potentialActions.filter((action, pos, self) => {
				return !action.disabled // ignore disabled actions
			})
			let targets = {}
			potentialActions = potentialActions.filter((action, pos, self) => {
				if (targets[action.id] === undefined) {
					targets[action.id] = []
				}
				targets[action.id].push(action.target)
				return self.findIndex(action2 => action.id === action2.id) === pos
			})
			potentialActions.forEach(action => {
				if (action.label.includes(' ')) {
					action.label = action.label.split(' ')[0]
				}
				if (targets[action.id] !== undefined) {
					action.label += ' (' + targets[action.id].length + '/' + this.totalServers + ')'
					if (targets[action.id].length > 1) {
						action.callback = () => this.groupActionCallback(action.id, targets[action.id], {})
					}
				}
			})
			this.sortActions(potentialActions)
			return potentialActions
		},
		dialogTitle () {
			return 'Actions'
		},
		dialogMessage () {
			return '(' + this.totalServers + ' servers)'
		},
		totalServers () {
			let count = 0
			if (this.servers !== undefined) {
				count += this.servers.length
			}
			// if (this.agents !== undefined) {
			// 	count += this.agents.length
			// }
			return count
		},

		activityFilterServers () {
			let result = []
			this.hexItems.forEach(item => {
				let server = item.target
				server = String(server).toLowerCase()
				server = server.substring(0, server.indexOf('.cachenetworks.com'))
				result.push(server)
			})
			return result
		},

	},
	watch: {
		actionPending (value) {
			if (this.servers !== undefined) {
				this.servers.forEach(server => {
					server.pending = value
				})
			}
			// if (this.agents !== undefined) {
			// 	this.agents.forEach(agent => {
			// 		agent.pending = value
			// 	})
			// }
		},
		request (value) {
			if (value !== undefined) {
				this.requestChanged(value)
			}
		},
	},
	mounted () {
		this.actionsRefreshInterval = setInterval(this.refreshActions, 1000)
	},
	methods: {

		refreshActions () {
			if (this.totalServers > 0) {
				this.actionsRefreshHint++
			}
		},
		stopRefreshingActions () {
			if (this.actionsRefreshInterval !== undefined) {
				clearInterval(this.actionsRefreshInterval)
			}
		},

		////////////////////////////////////////////////////////////////////////

		// Allows for an action to be triggered through a prop
		requestChanged (value) {
			if (typeof value === 'object' && value !== null) {
				this.requestActionByName(value.action)
			}
		},

		// Allows for an action to be triggered by name
		requestActionByName (action) {
			this.actions.forEach(possibleAction => {
				if (possibleAction.id === action) {
					return possibleAction.callback()
				}
			})
		},

		// Triggers an action against a single server - here I delegate all
		// the work of to a private instance of the singular action handler,
		// so when only one server is triggered we get the same behaviour as
		// triggering it directly.
		singularActionCallback (server, agent, action) {
			this.singularServer = server
			this.singularAgent = agent
			if (action === 'details') {
				this.$nextTick(() => this.singularOpen = true)
			} else {
				this.$nextTick(() => this.singularRequest = {'action': action}) // This triggers the action
			}
		},

		// Triggers an action against multiple servers at the same time. This
		// is the whole point of this component.
		groupActionCallback (action, targets, additional = {}) {
			let actionHuman = this.titleCase(action.replace('-', ' '))
			if (action === 'details') {
				return // ignore impossible action
			}
			if (action === 'refresh') { // refresh is safe and should be low friction
				if (this.refreshCallback !== undefined) {
					// this should return a promise
					return this.refreshCallback()
				}
				return Promise.reject('no refresh callback')
			}
			if (action === 'ping') { // it's ping - just do it
				this.performGroupActionsWithUI(
					action,
					actionHuman + ' ' + targets.length + ' servers',
					targets,
					additional,
				)
				return
			}
			let question = 'Perform ' + String(actionHuman).toLowerCase() + ' action against ' + targets.length + ' servers?'
			if (targets.length <= 5) {
				question += '\n' + targets.join('\n')
			}
			if (action === 'disable') {
				question += '\n(do not do this on an ' + this.serverType + ' server which is currently serving production traffic)'
			}
			if (action === 'delete' || action === 'deregister') {
				question += '\n(this is a destructive action - be sure you understand what this means before you confirm)'
			}
			this.requestActionWithUI(
				action,
				question,
				actionHuman + ' ' + targets.length + ' servers',
				targets,
				additional,
			)
		},

		////////////////////////////////////////////////////////////////////////

		requestActionWithUI (action, question, title, targets, additional = {}) {
			this.setActionPending()
			this.openConfirmDialog(
				title,
				question,
				() => this.performGroupActionsWithUI(action, title, targets, additional),
				this.clearActionPending
			)
		},

		////////////////////////////////////////////////////////////////////////

		performGroupActionsWithUI (action, title, targets, additional = {}) {

			this.setActionPending()
			this.openHexDialog(title, [])

			this.hexItems = []
			targets.forEach(target => {
				this.hexItems.push({
					id: action + '/' + target,
					group: 'task',
					type: 'action',
					action: action,
					target: target,
					title: this.shortNameFromHostname(target),
					name: this.nameFromHostname(target),
					pop: this.popFromHostname(target),
					pending: true,
					started: false,
					success: false,
					label: 'Pending ...',
					status: 'unknown',
				})
			})

			let numberPending = targets.length
			let numberRemaining = targets.length // not started yet
			let availableSlots = 3
			const getAvailableSlot = () => {
				return new Promise(resolve => {
					const check = () => {
						if (availableSlots > 0) {
							availableSlots--
							resolve()
						} else {
							setTimeout(check, 100)
						}
					}
					check()
				})
			}

			let numberSuccess = 0
			let numberFailed = 0
			const updateTitle = () => {
				let newTitle = title + ' ('
				let firstCount = true
				const addStat = (value, noun, plural) => {
					if (value <= 0) {
						return
					}
					if (firstCount) {
						firstCount = false
					} else {
						newTitle += ', '
					}
					newTitle += value + ' ' + noun
					if (value > 1) {
						newTitle += plural
					}
				}
				// addStat(numberPending, 'pending', '')
				addStat(numberRemaining, 'queued', '')
				let numberProcessing = numberPending - numberRemaining
				addStat(numberProcessing, 'processing', '')
				addStat(numberSuccess, 'success', 'es')
				addStat(numberFailed, 'failure', 's')
				newTitle += ')'
				this.hexTitle = newTitle
			}

			this.hexItems.forEach((meta, index) => {
				getAvailableSlot().then(() => {
					numberRemaining--
					meta.started = true
					meta.status = 'unhappy'
					this.$set(this.hexItems, index, meta)
					this.performActionWithUICallbacks(
						this.serverType,
						meta.target,
						meta.action,
						false,
						this.performAction, // perform
						this.refreshCallback, // refresh
						(message) => { // updateCallback
							console.debug('updateCallback', message, meta)
							meta.label = message
							this.$set(this.hexItems, index, meta)
							updateTitle()
						},
						() => { // completedCallback
							console.debug('completedCallback', meta)
							meta.pending = false
							meta.success = String(meta.label).includes('successfully') || String(meta.label).startsWith('API is performing')
							if (meta.success) {
								meta.status = 'good'
								numberSuccess++
							} else {
								meta.status = 'bad'
								numberFailed++
							}
							numberPending--

							this.$set(this.hexItems, index, meta)
							updateTitle()

							if (numberPending <= 0) {
								this.clearActionPending()
								this.hexShowClose = true
							}

							this.$nextTick(() => {
								availableSlots++
							})
						},
						additional, // Additional fields for API call
					)
				})
			})

		}, // end performGroupActionsWithUI

		////////////////////////////////////////////////////////////////////////

		nameFromHostname (hostname, lower) {
			if (hostname === undefined) {
				return undefined
			}
			if (hostname.includes('.')) {
				let nameParts = hostname.split('.')
				let name = nameParts[0].toLowerCase()
				if (lower === undefined || lower === false) {
					if (name.startsWith('frontend') ||
						name.startsWith('haproxy') ||
						name.startsWith('delivery') ||
						name.startsWith('storage')
					) {
						return name.slice(0, -1) + name.charAt(name.length - 1).toUpperCase()
					}
				}
				return name
			}
			return ''
		},
		popFromHostname (hostname) {
			if (hostname === undefined) {
				return undefined
			}
			let nameParts = hostname.split('.')
			if (nameParts.length > 1) {
				return nameParts[1].toUpperCase()
			} else if (nameParts.length > 0) {
				return nameParts[0].toUpperCase()
			} else {
				return ''
			}
		},
		shortPopCode (pop) {
			return pop.replace(/[0-9]/g, '')
		},
		shortNameFromHostname (hostname) {
			let name = this.nameFromHostname(hostname)
			let pop = this.popFromHostname(hostname)
			name = name.replace('frontend', 'f')
			name = name.replace('haproxy', 'h')
			name = name.replace('delivery', 'd')
			name = name.replace('storage', 's')
			name = name.replace('warmer', 'w')
			name = name.replace('logstash', 'l')
			name = name.replace('vector-ingest', 'vi')
			name = name.replace('vector-transform', 'vt')
			name = name.replace('vector', 'v')
			return name + '.' + pop
		},
		titleCase (str) {
			if (str === undefined || str === null) {
				return str
			}
			return str.toLowerCase()
				.replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())       // Initial char (after -/_)
				.replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()) // First char after each -/_
		},

		////////////////////////////////////////////////////////////////////////

		requestDialog (args) {
			// console.debug('[DEBUG] ActionsGroup: requestDialog', args)
			this.$emit('request-dialog', args)

			// console.debug('requestDialog', args)
			// switch (args.dialog) {
			// case 'activity':
			// 	this.$emit('request-action', {action: 'activity-dialog'})
			// 	break
			// case 'keys':
			// 	this.showKeysDialog(args.title, args.data)
			// 	break
			// case 'status-history':
			// 	this.showStatusHistory()
			// 	break
			// case 'config-history':
			// 	this.showConfigHistory()
			// 	break
			// case 'rollback':
			// 	this.showRollbackInterface()
			// 	break
			// default:
			// 	console.error('Unknown dialog request', args)
			// 	this.$emit('request-dialog', args)
			// }
		},


	},
}

</script>

<style lang="scss" scoped>

.actions {
	text-align: left;
}

.small-grid {
	padding: 0;
	margin-top: 0;
}

</style>
