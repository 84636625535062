<template>
	<div>
		<!-- This is a mixin so the template isn't used -->
		<!-- This is annoying but unavoidable; I think? -->
	</div>
</template>

<script>
/* eslint-disable vue/no-unused-components */

import ActionsButtons from './ActionsButtons'
import ActionsDialog from './ActionsDialog'
import ActionsFloatMenu from './ActionsFloatMenu'
import ActionsMenu from './ActionsMenu'
import ConfirmDialog from '../common/ConfirmDialog'
import KeysDialog from '../common/KeysDialog'

export default {
	name: 'ActionsUIMixin',
	components: {
		ActionsButtons,
		ActionsDialog,
		ActionsFloatMenu,
		ActionsMenu,
		KeysDialog,
		ConfirmDialog
	},
	props: [
		'serverType',
		'open',
		'mode',
	],
	data: function () {
		return {
			dialogOpen: false,

			actionPending: false,

			activityDialogOpen: false,
			activityDialogAddClose: false,
			activityDialogSelectedServer: undefined,
			activityDialogConfigMode: undefined,
			activityDialogMessage: undefined,

			disableDialogOpen: false,
			disableDialogServerName: undefined,
			disableDialogSaveStatus: undefined,

			keysTitle: '',
			keysOpen: false,
			keysData: [],
			keysShowClose: true,
			keysClearOnClose: true,

			hexTitle: '',
			hexOpen: false,
			hexItems: [],
			hexShowClose: true,

			confirmAction: undefined,
			cancelAction: undefined,
			confirmTitle: 'Confirm',
			confirmQuestion: '',
			confirmDialogOpen: false,
			confirmConfirmed: false,
			confirmCancelled: false,
			confirmItem: undefined,
		}
	},
	computed: {

		interactiveComponent () {
			switch (this.mode) {
			case 'buttons':
				return 'ActionsButtons'

			case 'menu':
				return 'ActionsMenu'

			case 'float-menu':
				return 'ActionsFloatMenu'

			default:
				return 'ActionsDialog'
			}
		},

		isOpen () {
			return this.open
		},

	},
	watch: {
		open (value) {
			this.openChanged(value)
		},
		dialogOpen (value) {
			this.$emit('update:open', value)
		},
	},
	mounted () {
		if (this.open) {
			this.openChanged(this.open)
		}
	},
	methods: {

		openChanged (value) {
			this.dialogOpen = value
			this.$emit('update:open', value)

		},

		setActionPending () {
			this.$emit('update:pending', true)
			this.actionPending = true
		},
		clearActionPending () {
			setTimeout(() => {
				this.$nextTick(() => {
					this.$emit('update:pending', false)
					this.actionPending = false
				})
			}, 250)
		},

		openKeysDialog (title, data, modal) {
			console.debug('[DEBUG] ActionsUI: openKeysDialog')
			if (title !== undefined) {
				this.keysTitle = title
			}
			if (modal === undefined) {
				modal = false
			}
			this.keysData = data
			this.keysShowClose = !modal
			this.keysOpen = true
		},
		openKeysDialogSimple (title, subtitle, message, modal) {
			this.openKeysDialog(title, [{
				'title': subtitle,
				'key': message
			}], modal)
		},
		updateKeysDialog (message, modal) {
			console.debug('[DEBUG] ActionsUI: updateKeysDialog')
			let subtitle = undefined
			if (this.keysData !== undefined && this.keysData.length > 0) {
				subtitle = this.keysData[0].title
			}
			this.openKeysDialogSimple(undefined, subtitle, message, modal)
		},
		keysDialogClosed () {
			console.debug('[DEBUG] ActionsUI: keysDialogClosed')
			if (this.keysClearOnClose) {
				this.clearActionPending()
			}
			this.keysData = []
			this.keysTitle = ''
		},

		openHexDialog (title, items) {
			console.debug('[DEBUG] ActionsUI: openHexDialog')
			if (title !== undefined) {
				this.hexTitle = title
			}
			this.hexItems = items
			this.hexShowClose = false
			this.hexOpen = true
		},
		updateHexDialog (items) {
			if (!this.hexOpen) {
				return
			}
			this.hexItems = items
		},
		hexItemClicked (event) {
			console.debug('[DEBUG] ActionsUI: hexItemClicked', event)
			this.keysClearOnClose = false
			let item = event.item
			this.openKeysDialogSimple(item.title, item.label, item, false)
		},
		hexDialogClosed () {
			console.debug('[DEBUG] ActionsUI: hexDialogClosed')
			this.keysClearOnClose = true
			this.clearActionPending()
			this.hexItems = []
			this.hexTitle = ''
		},

		openActivityDialog (serverId, serverType, message) {
			this.keysOpen = false
			this.activityDialogSelectedServer = serverId
			this.activityDialogConfigMode = serverType
			this.activityDialogMessage = message
			this.activityDialogAddClose = true
			this.activityDialogOpen = true
		},
		updateActivityDialog (message, modal) {
			this.activityDialogAddClose = modal !== true
			this.activityDialogMessage = message
		},
		activityDialogClosed () {
			console.debug('[DEBUG] ActionsUI: activityDialogClosed')
			this.activityDialogOpen = false
			this.clearActionPending()
		},

		openConfirmDialog (title, question, confirmAction, cancelAction) {
			this.confirmTitle = title
			this.confirmQuestion = question
			this.confirmAction = confirmAction
			this.cancelAction = cancelAction
			this.confirmConfirmed = false
			this.confirmCancelled = false
			this.confirmDialogOpen = true
		},
		confirmDialogConfirmed (event) {
			this.confirmConfirmed = true
			if (this.confirmAction !== undefined) {
				this.confirmAction(event)
			}
			this.confirmAction = undefined
			this.cancelAction = undefined
			this.confirmTitle = ''
			this.confirmQuestion = ''
		},
		confirmDialogCancelled () {
			this.confirmCancelled = true
			if (this.cancelAction !== undefined) {
				this.cancelAction()
			}
			this.confirmAction = undefined
			this.cancelAction = undefined
			this.confirmTitle = ''
			this.confirmQuestion = ''
		},
		confirmDialogClosed () {
			if (this.confirmConfirmed || this.confirmCancelled) {
				return
			}
			this.confirmDialogCancelled()
		},

		////////////////////////////////////////////////////////////////////////

		// performCallback should be this.performAction from the base component
		performActionWithUICallbacks (
			serverType,
			serverId,
			action,
			animateDots,
			performCallback, // must return a promise
			refreshCallback, // optional
			updateCallback, // optional
			completedCallback, // optional
			additional = {}, // optional
		) {
			// console.debug('performActionWithUICallbacks', serverType, serverId, action, animateDots)
			if (refreshCallback === undefined) {
				console.debug('NOTICE: performing action without a refresh callback (UI may be slow to update)')
			}
			if (updateCallback === undefined) {
				updateCallback = (message, modal) => {
					console.debug('update', message, modal)
				}
			}
			if (completedCallback === undefined) {
				completedCallback = () => {
					console.debug('completed')
				}
			}

			// Setup local state.
			let requiresServerReload = this.doesServerActionRequireReload(serverType, serverId, action)

			// Abuse the abort callback to know when progress has been made for
			// longer running processes and update the UI accordingly.
			let abortRequired = false // locally scoped abort tracker
			let showingTokenGenerationMessage = false
			let abortCallback = (processingStage) => {
				if (abortRequired || !this.actionPending) {
					abortRequired = true // latch; once we hit this once, we will always hit it.
					return true
				}
				if (processingStage === 'generatingToken') {
					showingTokenGenerationMessage = true
					updateCallback('Generating token ...', true)
				} else if (showingTokenGenerationMessage) {
					updateCallback('Please wait ...', true)
					showingTokenGenerationMessage = false
				}
				if (requiresServerReload && animateDots) {
					let keysData = this.keysData
					if (keysData[0].key.endsWith('...')) {
						keysData[0].key = keysData[0].key.slice(0, -3)
					}
					keysData[0].key += '.'
					this.keysData = keysData
				}
				return false
			}

			updateCallback('Please wait ...', true)
			// Start the API action ...
			// ... this feels asynchronous, but it's just timesharing via promises.
			performCallback( // @see ActionsBase::performAction() ~ line 331
				serverType, serverId, action, refreshCallback, abortCallback, additional
			).then(reply => {
				// Handle UI scenarios for the API reply ...

				// Special case for ping
				if (action === 'ping') {
					if (reply.attributes.result !== undefined && reply.attributes.result !== null) {
						updateCallback(reply.attributes.result)
					} else {
						updateCallback('PING FAILED')
					}
					return
				}

				// We'll want to make sure that our copy of the server data is
				// up to date immediately, so the user doesn't have to wait for
				// it to automatically update at some point in the future.
				if (refreshCallback !== undefined) {
					this.$nextTick(refreshCallback)
				}

				// If the API told us that the action is still pending then we
				// should reflect that in our completion message.
				if (reply.attributes.pending !== undefined && reply.attributes.pending === true) {
					updateCallback('API is performing the requested action.')
					return
				}

				// If we've been waiting for the server to reload then our
				// completion message should inform the user of the status;
				// as they will likely need to know if it failed.
				if (requiresServerReload) {
					if (reply.attributes.reload === undefined) {
						console.debug(reply) // debug output is good for unexpected scenarios
						updateCallback('ERORR: Invalid API response')
						return

					} else if (reply.attributes.reload.failed) {
						updateCallback('RELOAD FAILED')
						return
					}
				}

				// If we've got here then tell the user that everything is done.
				updateCallback('Completed successfully')

			}).catch(error => {
				console.error(serverType, serverId, action, error)
				updateCallback(error)

			}).finally(() => {
				abortRequired = true // if we haven't stopped everything yet, do so now!
				completedCallback()
			})

		},

		////////////////////////////////////////////////////////////////////////

		requestDisableWithUIDialog (serverName, desiredDisabled) {
			if (serverName === undefined) {
				if (this.serverName !== undefined) {
					serverName = this.serverName
				}
				if (serverName === undefined && this.server !== undefined) {
					serverName = this.server.attributes.hostname.FullName
				}
				if (serverName === undefined) {
					console.error('[ERROR] requestDisableWithUIDialog: serverName is undefined')
					return
				}
			}
			if (desiredDisabled === undefined) {
				desiredDisabled = []
			}
			this.disableDialogOpen = false
			this.$nextTick(() => {
				this.disableDialogServerName = serverName
				this.disableDialogOpen = true
			})
		},

		performDisablementSaveAction (serverName, modesToDisable, disableComment = '', additional = {}) {
			console.debug('[DEBUG] performDisablementSaveAction: ', serverName, modesToDisable, disableComment)
			return new Promise((resolve, reject) => {

				if (modesToDisable === undefined || modesToDisable.length === 0) {
					console.error('[ERROR] performDisablementSaveAction: modesToDisable is undefined or empty')
					return
				}

				// add the comment to the additional fields which are send to the API
				additional['comment'] = String(disableComment).trim()

				this.disableDialogSaveStatus = 'Preparing...'

				// This is sort of the same problem as the group and the multiple reconfigure.
				// It would be nice to just add it off to one of those other systems, but;
				// A) this code is intentionally separate from the group code and should not rely on it.
				// B) the multiple reconfigure code is too complex and heavy weight for this task.
				// So we reinvent the wheel here.

				let serverId = serverName
				const worker = () => {
					if (modesToDisable.length === 0) {
						this.disableDialogSaveStatus = 'Completed successfully'
						this.openKeysDialogSimple(
							'Disable',
							serverId,
							this.disableDialogSaveStatus,
							false
						)
						this.$nextTick(() => {
							this.disableDialogOpen = false
						})
						resolve()
						return
					}

					const serverType = modesToDisable.pop() // remove last element
					this.performActionWithUICallbacks(
						serverType,
						serverId,
						'disable',
						false, // animate dots
						this.performAction, // perform
						() => {  // refresh
							console.debug('[DEBUG] performDisablementSaveAction: refresh')
						},
						(message, modal) => {  // update
							console.debug('[DEBUG] performDisablementSaveAction: update', serverType, message, modal)
							if (typeof message !== 'string') {
								message = JSON.stringify(message)
							}
							this.disableDialogSaveStatus = serverType + ': ' + message
						},
						() => {  // completed
							console.debug('[DEBUG] performDisablementSaveAction: completed', serverType)
							if (this.disableDialogSaveStatus !== serverType + ': Completed successfully') {
								console.error('[ERROR] FAILED:', serverId, serverType, this.disableDialogSaveStatus)
								this.openKeysDialogSimple(
									'API error while performing disable action',
									'Failed to disable ' + serverType + ' configuration mode on ' + serverId,
									this.disableDialogSaveStatus,
									false
								)
								this.$nextTick(() => {
									this.disableDialogOpen = false
								})
								reject(this.disableDialogSaveStatus)
								return
							}
							this.$nextTick(worker) // Start the next one
						},
						additional, // Additional fields for API call
					)
				}

				this.setActionPending()
				this.$nextTick(worker) // Start the first one
			})
		},

		////////////////////////////////////////////////////////////////////////

	},
}

</script>

<style lang="scss" scoped>

.actions {
	text-align: left;
}

</style>
