<template>
	<div class="topappbar-container">
		<header id="app-bar" class="mdc-top-app-bar app-bar" data-mdc-auto-init="MDCTopAppBar">
			<div class="mdc-top-app-bar__row">
				<section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-start">
					<button v-if="showNav" aria-label="Open navigation menu"
							class="material-icons mdc-top-app-bar__navigation-icon mdc-icon-button">menu
					</button>
					<span class="mdc-top-app-bar__title">
						{{ title }}
					</span>
				</section>
				<section class="mdc-top-app-bar__section mdc-top-app-bar__section--align-end" role="toolbar">
					<a v-if="profileImage !== undefined"
					   class="mdc-icon-button mdc-top-app-bar__action-item"
					   :title="profileText"
					   @click="profileClick"
					><img style="max-height: 1.75em;"
						  :alt="profileText"
						  :src="profileImage"
					></a>
					<template v-for="action in actions">
						<button v-if="action.kind === 'button'"
								class="mdc-icon-button material-icons mdc-top-app-bar__action-item"
								data-mdc-auto-init="MDCRipple"
								:key="action.id"
								:title="action.arialabel"
								:arialabel="action.arialabel"
								@click="clickHandler(action)"
						>{{ action.icon }}
						</button>
						<button v-else-if="action.kind === 'image'"
								class="mdc-icon-button mdc-top-app-bar__action-item"
								:key="action.id"
								:title="action.text"
								@click="clickHandler(action)"
						><img style="max-height: 1.75em;"
							  :alt="action.text"
							  :src="action.image"
						></button>
						<a v-else
						   class="mdc-icon-button material-icons mdc-top-app-bar__action-item"
						   data-mdc-auto-init="MDCRipple"
						   :key="action.id"
						   :title="action.arialabel"
						   :arialabel="action.arialabel"
						   :href="action.href"
						   :target="action.external ? '_new': ''"
						   @click="clickHandler(action)"
						>{{ action.icon }}</a>
					</template>
				</section>
			</div>
			<div class="autoswitch-box" v-if="autoswitchStatus === false">
				<span>AUTOMATED CHANGES ARE CURRENTLY DISABLED</span>
			</div>
		</header>
		<div class="mdc-top-app-bar--fixed-adjust">
			<slot>
				<!-- Top App Bar Default Contents -->
			</slot>
		</div>
	</div>
</template>

<script>

import autoInit from '@material/auto-init'
// import topAppBar from '@material/top-app-bar';

export default {
	props: [
		'title',
		'actions',
		'showNav',
		'profileImage',
		'profileText',
		'autoswitchStatus',
	],
	data () {
		return {
			barEl: undefined,
			bar: undefined,
		}
	},
	mounted () {
		autoInit()
		this.barEl = document.getElementById('app-bar')
		this.bar = this.barEl.MDCTopAppBar
		this.bar.listen('MDCTopAppBar:nav', this.navButtonClick)
	},
	updated () {
		autoInit()
	},
	methods: {
		navButtonClick (event) {
			// console.debug('fired MDCTopAppBar:nav event')
			this.$emit('top-nav-click', event)
		},
		profileClick (event) {
			this.$emit('profile-click', event)
		},

		clickHandler (action) {
			this.$nextTick(() => {
				document.activeElement.blur()
				this.$nextTick(() => {
					document.getElementsByTagName('body')[0].focus()
				})
			})

			if (action === undefined || action === null) {
				return
			}

			if (action.callback !== undefined) {
				action.callback(action.id)
				return
			}

			this.$emit('click-' + action.id)

		},

	},
}
</script>

<style lang="scss">

/*@import "../../node_modules/@material/top-app-bar/dist/mdc.top-app-bar.css";*/
/*@import "../../node_modules/@material/icon-button/dist/mdc.icon-button.css";*/

.topappbar-container {
	display: flex;
	flex: auto;
	margin: 0;
	padding: 0;
	width: 100%;
	height: auto;
}

.mdc-top-app-bar {
	z-index: 8;
}

.mdc-top-app-bar--fixed-adjust {
	display: flex;
	flex: auto;
	margin: 0;
	padding: 64px 0 0 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

/* TODO Why isn't the MDC CSS styling the action items in the TopAppBar? */
.mdc-top-app-bar__action-item {
	padding: 12px;
	text-decoration: none;
}

.mdc-dialog__scrim {
	z-index: 10;
}

.mdc-dialog, .mdc-dialog__container, .mdc-dialog__surface {
	z-index: 12;
}

.autoswitch-box {
	background-color: salmon;
	border-top: 1px solid red;
	border-bottom: 1px solid red;
	font-family: monospace;
	font-size: 1.5em;
	color: white;
	font-weight: bold;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
	padding: 0.75em 1em;
	text-align: center;

	position: fixed;
	top: 0.25em;
	width: 100%;
}

</style>
